import * as React from "react";
const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={158.312}
        height={11.253}
        viewBox="0 0 158.312 11.253"
        {...props}
    >
      <path
          id="Path_1302"
          data-name="Path 1302"
          d="M0-1.547s39.686-2.03,77.231-2.03h71.857"
          transform="translate(4.724 8.076)"
          fill="none"
          stroke="#dbc446"
          strokeLinecap="round"
          strokeWidth={9}
      />
    </svg>
);
export default SVGComponent;
