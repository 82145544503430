import React from 'react';
import {Outlet} from 'react-router-dom';
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

export function BasicLayout() {
  return (
      <>
        <NavBar />
        <Outlet/>
        <Footer />
      </>
  )
}

