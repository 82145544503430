import React, {useEffect, useState} from 'react';
import styles from './styles.module.css';
import commonStyles from '../../Common/Styles/commonStyles.module.css';
import classNames from "classnames";
import {Link, useParams} from "react-router-dom";
import dummyPic from '../BlogsArchive/assets/dummy-Pic.png';
import BlueSection from "../../Components/BlueSection";
import BlogCard from "../../Components/BlogCard";
import facebookIcon from './assets/facebook.svg';
import twitterIcon from './assets/twitter.svg';
import linkedinIcon from './assets/linkedin.svg';
import { postPath} from "../../config/config";
import { Navigate, useNavigate } from "react-router-dom";
import parse from 'react-html-parser';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

export default function (props) {

  const [loading, setLoading] = useState(true);
  const [loadingRelatedPosts, setLoadingRelatedPosts] = useState(true);
  const [data, setData] = useState(null);
  const [relatedPostData, setRelatedPostData] = useState(null);
  const {id} = useParams();
  const navigate = useNavigate();

  const embedOption = '?_embed=true';

  useEffect(() => {
    setLoading(true);
    fetch(postPath + embedOption + '&slug=' + id)
        .then(response => response.json())
        .then(data => {
          setData(data);
          setLoading(false);
          listenerRoutineforRelatedPosts();
        }).catch(err => console.log(err));
  },[id])

  function listenerRoutineforRelatedPosts () {

    setTimeout(() => {
      const relatedPostContainer = document.getElementById('related-post-container');
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            fetch(postPath + embedOption + '&sticky=true')
                .then(response => response.json())
                .then(data => {
                  setRelatedPostData(data);
                  setLoadingRelatedPosts(false);
                  observer.unobserve(relatedPostContainer);
                }).catch(err => console.log('err, ', err));
          }
        })
      })
      observer.observe(relatedPostContainer);
    }, 400);


  }
  return (<div className={classNames(styles.pageContainer, commonStyles.pageContainerWidth)}>
    {loading ? <div className={commonStyles.loaderContainer}><div className={commonStyles.loader}/></div> :
    data.length ? <div>
      <p className={styles.breadCrumbsContainer}>
        <Link to={'/'}>Home</Link> <span>/</span>
        <Link to={'/blogs'}>Blog</Link> <span>/</span>
        <a>{data[0].title.rendered || ''}</a>
      </p>

      <section className={styles.imgContainer}>
        <img src={data[0]._embedded['wp:featuredmedia'][0]?.source_url || dummyPic} className={styles.imgBanner} alt={'page-banner'}/>
      </section>

      <div className={styles.lowerPageContainer}>
        <section className={styles.pageHeader}>
          <div className={styles.headerText}>
            {data[0].title?.rendered}
          </div>
          <div className={styles.shareBtnContainer}>
            <FacebookShareButton
                url={window.location.href}
                quote={data[0].title?.rendered}
            >
                <span className={styles.facebookIcon}>
                  <img src={facebookIcon} alt={'icon'}/>
                </span>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}
                                quote={data[0].title?.rendered}>
                <span className={styles.twitterIcon}>
                  <img src={twitterIcon} alt={'icon'}/>
                </span>
            </TwitterShareButton>
            <LinkedinShareButton url={window.location.href}
                                 quote={data[0].title?.rendered} title={data[0].title?.rendered}
              summary={data[0].excerpt?.rendered}
              source={window.location.href}>
                <span className={styles.linkedinIcon}>
                  <img src={linkedinIcon} alt={'icon'}/>
                </span>
              </LinkedinShareButton>
          </div>
        </section>
        <section className={styles.blogContentContainer}>
          {parse(data[0].content?.rendered)}
        </section>
        <section className={styles.relatedPostContainer} id={'related-post-container'}>
          <div className={styles.sectionHeader}>Related Posts</div>
          <div className={styles.blogCardContainer}>
            {/*{[...Array(5)].map((val, key) => <BlogCard key={key}/>)}*/}
            {relatedPostData?.map(post => <BlogCard onClick={() => navigate('/blogs/' + post?.slug)} key={post?.id} title={post?.title?.rendered}
                                                    img={post?._embedded['wp:featuredmedia']['0']?.media_details?.sizes?.medium_large?.source_url} content={post?.excerpt?.rendered} />)}
          </div>
          {loadingRelatedPosts && <div className={commonStyles.loaderContainer}>
            <div className={commonStyles.loader}/>
          </div>}
        </section>
        <BlueSection/>
      </div>
    </div> : <Navigate to={'/not-found'}/>}
  </div>)
}