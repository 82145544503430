import React from 'react';
import styles from './styles.module.css';
import stylesHomepage from '../../Pages/HomePage/styles.module.css';
import classNames from "classnames";

const TestimonialCard = props => {
  const {ele, className} = props;
  return (
      <div className={classNames(styles.homeAgentTestimonialSectionCard, className, "col-6")}>
    <div>
      <div className="d-flex align-items-start">
        <img
            loading="lazy"
            width={54}
            src={ele?.image}
            className={styles.homeAgentTestnomialAvatar}
            alt="photo"
        />
        <div className="ms-3">
          <div className={classNames(styles.homeAgentTestimonialMemberName, "fs-6 fw-bold")}>
            {ele?.name}
          </div>
          <div className={classNames(styles.homeAgentTestimonialMemberCompany, "fs-6")}>
            {ele?.company}
          </div>
          <div className={classNames(styles.testimonialContent, "mb-2 pe-1 pb-4 mt-4")}>
            {ele?.testimonial}
          </div>
          <div className={styles.btnContainer}>
            <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfvkWUbSxvOQZpD1oenO-vnH_7ilPXmH2e0Sw8jBuzppby_Sg/viewform"
                alt="plans-button"
                target={'_blank'}
                className={classNames(stylesHomepage.btnTheme, styles.testimonialBtn, "btn")}
            >
              Join Us 📅
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>)
}
export default TestimonialCard;