import React, {useMemo, useEffect} from "react";
import NumberScroller from "react-number-scroller";
import styles from "./styles.module.css";
import AOS from 'aos';
import bgFirstSection from './assets/bgfirstSectionComp.webp';
import bgPipelineSection from './assets/bgpipelineSectionComp.webp';
import bgClientsSection from './assets/bgClientsSectionComp.webp'
import bgCallToActionSection from './assets/bgCallToActionSectionComp.webp';
import StrikeLineSVG from "./strikeLineSVG";
import SmallUnderlineSVG from "./smallUnderlineSVG";
import TalentUnderlineSVG from "./talentUnderlineSVG";
import AiUnderlineSVG from "./AiUnderlineSVG";
import FindUnderlineSVG from "./FindUnderlineSVG";
import clientLogo1 from "./assets/xencov.jpeg";
import clientLogo2 from "./assets/codalien.jpeg";
import clientLogo3 from "./assets/dineout.jpeg";
import clientLogo4 from "./assets/zuddl.jpeg";
import clientLogo5 from "./assets/kodekloud.jpeg";
import clientLogo6 from "./assets/mindpeers.jpeg";
import clientLogo7 from "./assets/kalendar.jpeg";
import clientLogo8 from "./assets/danip.jpeg";
import allinLogo from "./assets/allinLogo.svg";
import fiveHundredLogo from "./assets/fiveHundredLogo.svg";
import avatar1 from "./assets/avatar.svg";
import avatar2 from "./assets/avatar2.svg";
import avatar3 from "./assets/avatar3.svg";
import avatar4 from "./assets/avatar4.svg";
import icebergImg from './assets/iceberg.svg';
import aboutSectionIllustration from "./assets/aboutSectionillustration.svg";
import aboutSectionIllustration2 from "./assets/aboutSectionIllustration2.svg";
import aboutSectionIllustration3 from "./assets/aboutSectionIllustration3.svg";
import aboutSectionIllustration4 from "./assets/aboutSectionIllustration4.svg";
import nikhilChainani from './assets/NikhilChainani.jpg';
import TestimonialCard from "../../Components/TestimonialCard";
import classNames from "classnames";
import commonStyles from '../../Common/Styles/commonStyles.module.css'
import {Helmet} from "react-helmet";

const Homepage = () => {
  const isMobileDevice = useMemo(() => window.matchMedia('(max-width: 767px)').matches,[]);

  const testimonialsSectionData = [{
    image: nikhilChainani,
    name: 'Nikhil Chainani',
    company: 'Co-founder & CEO',
    linkedIn: '#',
    testimonial: 'Join our global talent referral marketplace to shape the future of hiring. Access job openings, make a positive impact, and earn commissions. Be part of a passionate community. Don\'t miss this exciting opportunity to advance your career.'
  }
  ];

  useEffect (() => {
    AOS.init({once: true});

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    let firstLinedSvg = document.querySelector('#firstLinedSvg');
    let secondLinedSvg = document.querySelector('#secondLinedSvg');
    let thirdLinedSvg = document.querySelector('#thirdLinedSvg');
    let fourthLinedSvg = document.querySelector('#fourthLinedSvg');
    let fifthLinedSvg = document.querySelector('#fifthLinedSvg');
    let sixthLinedSvg = document.querySelector('#sixthLinedSvg');
    let seventhLinedSvg = document.querySelector('#seventhLinedSvg');
    let eigthLinedSvg = document.querySelector('#eigthLinedSvg');
    let ninthLinedSvg = document.querySelector('#ninthLinedSvg');

    observer.observe(firstLinedSvg);
    observer.observe(secondLinedSvg);
    observer.observe(thirdLinedSvg);
    observer.observe(fourthLinedSvg);
    observer.observe(fifthLinedSvg);
    observer.observe(sixthLinedSvg);
    observer.observe(seventhLinedSvg);
    observer.observe(eigthLinedSvg);
    observer.observe(ninthLinedSvg);
  },[])
  const animationDuration = useMemo(() => window.matchMedia("(max-width: 767px)").matches ? 90 : 1000, [])
  return (
      <div>
        <Helmet>
          <title>Suitable AI - Global Talent Referral Marketplace</title>
          <link href={window.location.href} rel="canonical"/>
        </Helmet>
        <div className={styles.homeAgencyFirstSection} style={{backgroundImage: `url(${bgFirstSection})`}}>
          <div className="container-fluid">
            <div className="row">
              <div className={classNames(commonStyles.pageContainerWidth, "col position-relative")}>
                <div className={classNames(styles.homeAgencyFirstSectionContent, "pb-5")}>
                  <div data-aos-duration={animationDuration} data-aos='fade-up'
                       className={classNames(styles.homeAgencyFirstSectionHeading, "text-white text-center pt-5 mt-1")}>
                    <span className={styles.linedSectionContainer}>10x your income
                    <StrikeLineSVG id={'firstLinedSvg'} className={styles.firstLinedImg} />
                  </span> with <br/>
                    Suitable AI
                    <span className="text-white fw-semibold">

                  </span>
                  </div>
                  <div data-aos-duration={animationDuration} data-aos='fade-up'
                       className={classNames(styles.homeAgencyFirstSectionSummary, "fst-italic")}>
                    Our mission is to help our partners
                    <span className={styles.homeAgencyFirstSectionSummaryHighlight}>
                       &nbsp;close roles faster & enable them earn 10x
                    </span>
                  </div>
                  <div data-aos-duration={animationDuration} data-aos="fade-up"
                       data-aos-anchor-placement="top-bottom"
                       className={classNames(styles.homeAgencyTagsSectionContainer, " mt-5 mb-4")}>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Choose your mandates</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Track Candidates</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Boosted Payouts</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Talent pool Sharing</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Virtual Portfolio</span>
                  </div>
                  <div className={styles.firstSectionBtnContainer}>
                    <a
                        href="https://app.suitable.ai/login"
                        target='_blank'
                        rel="noreferrer"
                        className={classNames(styles.btnTheme, "btn")}
                    >
                      Start Earning 💸
                    </a>
                  </div>
                  {/*<div className={classNames(styles.homeAgencyFirstSectionSmallText, "mt-3")}>Employers - <a className={styles.linkText} href={'https://calendly.com/nikhil-suitableai/30min'} target="_blank">Click here</a> to know more.</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.homeAgencyClientsSection, "mb-5")} style={{backgroundImage: `url(${bgPipelineSection})`}}>
          <div data-aos-duration={animationDuration}
               className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyClientsSectionBox, "pt-3")}>
            <div className={classNames(styles.homeAgencyClientsSectionHeading, "pb-3")}>
              Our Pipeline Quality (Shortlist Rates)
            </div>
            <div
                className={classNames(styles.homeAgencyClientsSectionContent, "justify-content-between mt-3 d-flex pb-4")}>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo1}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Xencov</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={57} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo2}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Codalien</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={58} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo3}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Dineout</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={62} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo4}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Zuddl</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={58} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo5}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>KodeKloud</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={68} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo6}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Mindpeers</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={68} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo7}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Kalendar AI</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={92} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo8}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Danip</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={80} renderFrequency={70}/>%</div>
              </div>
            </div>
          </div>
        </div>
        <section data-aos-duration={animationDuration} data-aos={"fade-up"}
                 className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyBackedBySection)}>
          <div className={classNames(styles.homeAgencyBackedBySectionBox, 'gap-2 justify-content-center')}>
            <div className={classNames(styles.homeAgencyBackedByHeading, 'fw-bold fs-3')}>Backed By</div>
            <div className={classNames(styles.homeAgencyBackedByContentBox, 'd-flex align-items-center')}>
              <span>
                <a href={'https://500.co/'} target={'_blank'}>
                  <img loading="lazy" src={fiveHundredLogo} alt={'logo'}
                       className={styles.homeAgencyBackedUpSectionLogo} width={124} height={124}/>
                </a>
              </span>
              <span>
                <a href={'https://allincapital.vc/'} target={'_blank'}>
                  <img loading="lazy" src={allinLogo} alt={'logo'}
                       className={styles.homeAgencyBackedUpSectionLogo} width={124} height={124}/>
                </a>
              </span>
            </div>
            <div
                className={classNames(styles.homeAgencyBackedByContentBox, 'd-flex align-items-center')}>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar1} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Patrick Lee</div>
                  <div>Co-founder</div>
                  <div>Rotten Tomatoes</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar2} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Liron Shapira</div>
                  <div>Co-founder</div>
                  <div>Relationship Hero</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar3} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Abhishek Ayyagari</div>
                  <div>Co-founder</div>
                  <div>yBANQ, acquired by Clear</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar4} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Taher Savliwala</div>
                  <div>Co-founder</div>
                  <div>Relief Technologies Inc</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos-duration={animationDuration} data-aos={"fade-up"}
                 className={classNames(styles.homeAgencySecondHeadingSection, "text-center fs-1")}>
          Created by {isMobileDevice ? <br /> : ''}<span
            className={classNames(styles.linedSectionContainer, 'fw-bold')}>Founders & Recruiters
          <StrikeLineSVG id={'secondLinedSvg'} className={classNames(styles.firstLinedImg, styles.foundersLinedImg)} />
        </span> <br/>
          who Empathise with your pain
        </section>
        <div
            className={classNames(commonStyles.pageContainerWidth, styles.homeAgentAboutSection)}>
          <div className={'home-agent-about-section-alternating-illustration-section'}>
            <div className={'home-agent-about-alternating-illustration-section'}>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator, "row")}>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      <span className={'position-relative'}>Showcase
                      <SmallUnderlineSVG id={'thirdLinedSvg'} className={styles.firstAboutSectionHeadUnderline} />
                    </span> your strength and portfolio
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Create your personal <strong>virtual portfolio to gain credibility</strong> and share with prospects
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Share candidate and client testimonials and <strong>book 10x more meetings</strong>
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Build your <strong>Suitable Score and get unfair advantages</strong> based on your ratings on Suitable AI
                    </div>

                  </div>
                </div>
                <div data-aos-duration={animationDuration} data-aos="fade-up"
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration}
                      alt="illustration"
                  />
                </div>
              </div>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator2, "row")}>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, styles.homeAgentSectionIllustrationContainer2, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration2}
                      alt="illustration"
                  />
                </div>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      <span className="position-relative">Choose jobs
                        <AiUnderlineSVG id={'fourthLinedSvg'} className={styles.aiScreeningLine} />
                      </span> based on your preferences
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Choose your <strong>mandates based on preferences </strong>
                      including geography, industry and experience
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 See the <strong>payout % and audio briefings</strong> of the client requirement to get clarity on the role
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Get armed with the <strong>relevant marketing tools</strong> to share with the candidates instantly
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div className={'home-agent-about-alternating-illustration-section mt-5'}>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator, "row")}>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      Get the <span className="position-relative">technical edge
                      <FindUnderlineSVG id={'fifthLinedSvg'} className={styles.findUnderline}/>
                    </span> to  track, manage and close
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Engage potential candidates</strong> by providing them with a clear & compelling narrative about the company and its culture
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Keep track of the candidates status</strong> and feedback from the client
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Receive nudges to <strong>follow-up with candidates</strong> before and after interviews
                    </div>
                  </div>
                </div>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, styles.homeAgentSectionIllustrationContainer3, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration3}
                      alt="illustration"
                  />
                </div>
              </div>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator2, "row")}>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration4}
                      alt="illustration"
                  />
                </div>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      Earn from your <span className="position-relative">passive database
                      {/*<SmallUnderlineSVG id={'sixthLinedSvg'} className={styles.laserFocusLine} />*/}
                      <StrikeLineSVG id={'sixthLinedSvg'} className={styles.firstLinedImg} />

                    </span> of talent
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Upload and <strong>manage your exclusive talent pool</strong> of referred candidates on Suitable AI
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Get <strong>AI suggestions for the relevant match candidates</strong> for new opportunities
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Collaborate in teams and split fees</strong> on candidate sharing from talent pools
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div
            className={classNames(styles.homeAgencyTestimonialContainer, "mt-5 pt-5")}
            style={{backgroundImage: `url(${bgClientsSection})`}}
            id="testimonials"
        >
          <div className={"container-fluid pt-3" }>
            <div className="row">
              <div className="col">
                <div className="mt-2">
                  <div className={classNames(styles.ourClientsHeading, "fw-bold fs-2 text-center")}>
                    Join our <span className="position-relative">Daily Webinar
                        <AiUnderlineSVG id={'fourthLinedSvg'} className={styles.aiScreeningLine} />
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 overflow-hidden position-relative">
              <div className="col-12">
                <div className={styles.homeAgentTesimonialCardsContainer}>
                  {/*{testimonialsSectionData?.map(ele => <TestimonialCard key={ele.name} ele={ele} />)}*/}
                  <TestimonialCard className={styles.testimonialSectionCard} ele={testimonialsSectionData[0]}/>
                </div>
              </div>
            </div>
            {/*<div className={"row d-md-none"}>*/}
            {/*  <div*/}
            {/*      className={classNames(styles.homeAgencyClientsCardsContainer, "col mt-5 pt-5 d-flex flex-column gap-4 align-items-center")}>*/}
            {/*    { testimonialsSectionData?.slice(0, 4).map(ele => <TestimonialCard key={ele.name} ele={ele} />)}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

        <section className={classNames(styles.homeAgencyIcebergSection, "text-center text-white")}>
          <div className={classNames(commonStyles.pageContainerWidth, 'container-fluid')}>
            <div className="row">
              <div className="col" data-aos-duration={750} data-aos="fade-up">
                <div className={classNames(styles.homeAgencyIcebergHeading, "fw-bold fs-1")}>
                  How will Suitable AI 10x my <span className="position-relative">earnings?
                  <SmallUnderlineSVG id={'seventhLinedSvg'} className={styles.foundersLinedImg} />
                </span>
                </div>
                <div className={classNames(styles.homeAgencyIcebergTextContent, "mt-3")}>
                  There are many processes and technologies at work behind the scenes to make this <br/> possible, ensuring that you get the best talent in the fastest way possible
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5">
              <div className="col">
                <img loading="lazy" src={icebergImg} className={styles.homeAgencyIcebergSectionIllustration} height='auto' alt={'illustration-photo'} width={'100%'}/>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.callToActionSectionContainer} style={{backgroundImage: `url(${bgCallToActionSection})`}}>
          <div className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyCallToActionSectionBox)}>
            <div className="pb-3 fs-1">
              Join our 2000+ Talent   <span className="position-relative">Partner
              <SmallUnderlineSVG id={'eigthLinedSvg'} className={styles.foundersLinedImg} />
            </span><br/>
              <span className="position-relative">network
                <TalentUnderlineSVG id={'ninthLinedSvg'} className={styles.laserFocusLine} />
              </span> today!
            </div>
            <div className={styles.homeAgencyCallToActionSectionContent}>
              See the platform in action and discover the difference it can make for your earnings.
            </div>
            <div className="mt-4 pb-4">
              <a
                  href="https://app.suitable.ai/login"
                  target='_blank'
                  rel="noreferrer"
                  className={classNames(styles.btnTheme, "btn mt-4")}
              >
                Start Earning 💸
              </a>
            </div>

          </div>
        </section>

      </div>
  );
};

export default Homepage;