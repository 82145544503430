import React from 'react';
import styles from './styles.module.css';
import suitableLogo from './assets/suitableLogo.svg'
import youtubeLogo from './assets/youtubeLogo.svg'
import twitterLogo from './assets/twitterLogo.svg'
import instagramLogo from './assets/instagramLogo.svg'
import usFlag from './assets/us-flag.svg'
import indiaFlag from './assets/india-flag.svg'
import linkedInLogoBlack from './assets/linkedin-black.svg';
import classNames from "classnames";
import commonStyles from '../../Common/Styles/commonStyles.module.css';
import {Link} from "react-router-dom";

const Footer = () => {
  return <footer>
    <div className={styles.FooterContainer}>
      <div className={classNames(commonStyles.pageContainerWidth, 'container-fluid mt-2')}>
        <div className={'row'}>
          <div className={"col-md-5 mb-3"}>
            <div className={styles.homeAgencyFooterSectionHeading}>
              <img alt='logo' src={suitableLogo} className={styles.homeAgencyFooterSectionImage} width={100} height={'auto'}/>
            </div>
            <div className={styles.homeAgencyFooterContentContainer2}>
              <img alt='logo' src={usFlag} className={styles.homeAgencyFooterFlagImageContainer} width={30} height={'auto'}/>
              <div className={styles.homeAgencyFooterContentRightSection}>
                <div className={styles.homeAgencyFooterSectionHeading}>Suitable AI Inc</div>
                <div className={classNames(styles.homeAgencyFooterSectionContent, "mt-2")}>
                  16192, Coastal Highway Lewes, <br/>
                  Delaware - 19958
                </div>
              </div>
            </div>
            <div className={styles.homeAgencyFooterContentContainer2}>
              <img loading="lazy" alt='logo' src={indiaFlag} className={styles.homeAgencyFooterFlagImageContainer} width={30} height={'auto'}/>
              <div className={styles.homeAgencyFooterContentRightSection}>
                <div className={styles.homeAgencyFooterSectionHeading}>Suitable Technology Private Limited</div>
                <div className={classNames(styles.homeAgencyFooterSectionContent, "mt-2")}>
                  A-49, Engine House, <br/>
                  Mohan Estate, New Delhi - 110044
                </div>
              </div>
            </div>
          </div>
          <div className={'col-md-2 mb-3'}>
            <div className={styles.homeAgencyFooterSectionHeading}>Quick Links</div>
            <div className={styles.homeAgencyFooterContentContainer}>
              <Link to={'/about-us'}>About us</Link>
            </div>
            {/*<div className={styles.homeAgencyFooterContentContainer}*/}
            {/*     onClick={() => window.open('https://scouts.app.suitable.ai/careers', '_blank')}>Careers*/}
            {/*</div>*/}
            <div className={styles.homeAgencyFooterContentContainer}>
              <Link to={'/blogs'}>Blogs</Link>
            </div>
            <div className={styles.homeAgencyFooterContentContainer}
                 onClick={() => window.open('https://www.notion.so/suitable/b2946366d98c4c0dabbadb8f92236a8c?v=7cc0c1ea4c85425aab650ea92d5fe5f9', '_blank')}>FAQs
            </div>
          </div>
          <div className={'col-md-2 mb-3'}>
            <div className={styles.homeAgencyFooterSectionHeading}>For Employers</div>
            <div className={styles.homeAgencyFooterContentContainer}>
              <a href='https://calendly.com/nikhil-suitableai/30min' target="_blank" rel="noreferrer">
                Book a Demo
              </a>
            </div>
            <div className={styles.homeAgencyFooterContentContainer} onClick={() => {
              location.href = "mailto:hello@suitable.ai"
            }}>hello@suitable.ai
            </div>
          </div>
          <div className={'col-md-3 mb-3'}>
            <div className={styles.homeAgencyFooterSectionHeading}>Support</div>
            <div className={styles.homeAgencyFooterContentContainer} onClick={() => {
              location.href = "mailto:support@suitable.ai"
            }}>support@suitable.ai
            </div>
          </div>

        </div>
      </div>
    </div>
    <hr className="mt-3"/>
    <div
        className={classNames(commonStyles.pageContainerWidth, "d-flex gap-4 align-center justify-content-center justify-content-md-start")}>
      {/*<img loading="lazy" alt'logo src={fbLogo} className={styles.homeAgencyFooterIconClass} width={25} height={25}/>*/}
      <img loading="lazy" alt='logo' src={youtubeLogo} onClick={() => window.open('https://www.youtube.com/@suitable4073', '_blank')}
           className={styles.homeAgencyFooterIconClass} width={25} height={25}/>
      <img loading="lazy" alt='logo' src={twitterLogo} onClick={() => window.open('https://twitter.com/suitableai', '_blank')}
           className={styles.homeAgencyFooterIconClass} width={25} height={25}/>
      <img loading="lazy" alt='logo' src={linkedInLogoBlack}
           onClick={() => window.open('https://www.linkedin.com/company/suitable-ai/', '_blank')}
           className={styles.homeAgencyFooterIconClass} width={25} height={25}/>
      <img loading="lazy" alt='logo' src={instagramLogo} onClick={() => window.open('https://www.instagram.com/suitable.ai/', '_blank')}
           className={styles.homeAgencyFooterIconClass} width={25} height={25}/>
    </div>
    <div className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyFooterCopyrightInfoSection, "row mt-3 pb-2 ")}>
      <div className={'col-md-6 mb-3 d-flex align-items-center ps-0 justify-content-center justify-content-md-start'}>©
        2023 Suitable AI Inc. Copyright and All rights reserved.
      </div>
      <div className={'col-md-6 mb-3 align-center d-flex justify-content-center justify-content-md-end'}>
        <Link to={'terms-condition'} className={'btn'}>Terms and
          Conditions</Link>
        <Link to={'privacy-policy'} className={'btn'}>· Privacy Policy</Link>
      </div>
    </div>
  </footer>
}

export default Footer;