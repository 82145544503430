import React from 'react';
import styles from './styles.module.css';

const PrivacyPolicy = props => {

  return <div className={styles.pageContainer}>
    <div className="container">
      <h4>Privacy Policy</h4>
      <p>Thanks for entrusting Suitable.ai with data and your pertinent personal information. Handling your data is a
        serious responsibility, and we want you to know how we go about doing that.<br/>
          Effective date | 10th March 2021<br/>
          Last date Modified | 10th March 2021</p>
      <p>&nbsp;</p>
      <p><b>Thanks for entrusting Suitable.ai with data and your pertinent personal information. Handling your data is a
        serious responsibility, and we want you to know how we go about doing that. Suitable Technology Private Limited
        (Suitable.ai) is registered in India as a Private limited company with number U74999DL2021PTC378224.</b></p>
      <h5>Introduction</h5>
      <p>We are Suitable Technology Private Limited, a provider of talent selection services based in India. All
        references in this policy to “Suitable”, “Suitable.ai”, “our”, “us” or “we” refer to Suitable Technology Private
        Limited, or our suppliers which provide services to us, as appropriate.<br/>
          We are committed towards protecting and safeguarding the Personal Information (as defined in section 1.1
          herein below) of all and any of the users (including but not limited to customers, users, end-users and
          candidates) “users”, “you”, “your” which may be collected by us in consequence of your use / access / browsing
          of the Website and/or the Services. Any Personal Information collected from you on the Website shall be
          governed by this privacy policy (“Privacy Policy”). This Privacy Policy provides for the nature and type of
          Personal Information collected on the Website and/or during the provision of Services, the manner in which it
          is collected, use of such information, and disclosure and handling of such information by us. The users are
          advised to read this Privacy Policy, along with our Terms of Use which set out the legal terms governing
          delivery, access and use of the Services (together the “Terms”), before using / accessing / browsing the
          Website and/or availing Services and providing us with your Personal Information.<br/>
          This Privacy Policy is published in compliance with, inter alia:<br/>
          – Section 43A of the Information Technology Act, 2000;<br/>
          – Rule 4 of the Information Technology (Reasonable Security Practices and<br/>
          – Procedures and Sensitive Personal Data or Information) Rules, 2011;and- Rule 3(1) of the Information
          Technology (Intermediaries Guidelines) Rules, 2011.<br/>
          This Privacy Policy lets you know what happens to any personal data that you give to us or that we collect
          from you. We collect only the minimum amount of Personal Information necessary for you to make use of our
          Services; and we will only ever use your data as this Privacy Policy describes.<br/>
          By using / accessing / browsing / the Website and/or Services, you signify your consent for Suitable.ai to
          manage your Personal Information in accordance with this Privacy Policy. If you do not agree with this Privacy
          Policy, then such you shall not be authorized to use / access / browse the Website and / or the Services.</p>
      <h5>APPLICABILITY OF THIS PRIVACY POLICY</h5>
      <p>This Privacy Policy applies to the users who are using / accessing / browsing of Suitable Technology Private
        Limited’s website at www.suitable.ai (the “Website“), our web application available on the Website (the
        “Services“) and other interactions (e.g. customer service inquiries, live chat etc.) you may have with
        Suitable.ai. This Privacy Policy does not apply to any third party applications or software that integrate with
        the Services, or any other third party products, services or businesses, where we are not the controller of your
        personal data. The collection and processing of information, including Personal Information, in the course of
        other contractual or commercial engagements that we may enter into with you, will be done in accordance with the
        specific terms applicable thereto, and not by this Privacy Policy. By using / accessing / browsing the Website
        and/or Services, or by otherwise giving us your information, you confirm that you have the capacity toenter into
        a legally binding contract and that you have read, understood and agreed to the practices and policies outlined
        in this Privacy Policy and our Terms of Service. You hereby consent to our collection, use, sharing, and
        disclosure of your information, including Personal Information, as described in this Privacy Policy.</p>
      <h5>1. HOW WE HANDLE YOUR DATA</h5>
      <h6>1.1 Information We Collect</h6>
      <p>Information from website browsers<br/>
        What do we collect?<br/>
        If you are browsing the Website, we collect the same basic information that many other websites collect. We use
        common internet technologies, such as web server logs. This is information we collect from everybody who visits
        our Website, whether they have an account or not.<br/>
        The information we collect about all visitors to our Website includes the visitor’s browser type, language
        preference, referring site, and the date and time of each visitor request. We also collect potentially
        personally-identifying information like Internet Protocol (IP) addresses.<br/>
        Why do we collect this?<br/>
        This is done improve the visitor experience and provide better content and service to our current as well as
        prospective customers based on their geography and potential usage of our product and services.<br/>
        Information from users uploading files<br/>
        What do we collect?<br/>
        If you login, upload file(s) or source candidate files using our Services, we collect your email address,
        contact information, location, along with details of the filename or file URL.<br/>
        Why do we collect this?<br/>
        We collect this information in order to provide our talent selection services to you – without this information
        we are not able to upload files, analyze them and provide you with talent selection service.<br/>
        Information from users with accounts<br/>
        What do we collect?<br/>
        If you create an account with us on our Website, we require some basic information at the time of account
        creation. You will create your own password, and we will ask you for your full name and a valid email address.
        We will also collect your billing details, including credit/debit card information (card name, number, expiry
        and CVV), a billing address and for some customers a valid GST/ VAT number. You have the option to either save
        or delete information relating to your credit/debit card information (card name, number, expiry and CVV) on the
        Website or Service.<br/>
        Why do we collect this?<br/>
        – We need this information in order to set up your account, and to provide any services you have requested from
        us.<br/>
        – We use your email address to identify you on Suitable.ai and send important system and account notices to you.
        We do not use your email address for marketing purposes unless you have specifically consented to us doing this.
        We don’t share or sell your email address with any third parties.<br/>
        – We limit our use of your Personal Information to the purposes listed in this Privacy Policy. If we need to use
        your Personal Information for otherpurposes, we will ask your permission first. You can always see what
        information we have, how we’re using it, and what permissions you have given us in your user profile<br/>
        People who contact us with enquires<br/>
        What do we collect?<br/>
        If you contact us with a sales or support enquiry, we will collect the information provided when you correspond
        with us, such as your name, your address, your email address and your telephone number.<br/>
        Why do we collect this?<br/>
        We will collect, use and store the kinds of information as set out in this Section 1.1 (“Personal Information”)
        to deal with any enquiries or issues you have about our Services, including any questions you may have about how
        we collect, store and use your Personal Information, or any requests made by you for a copy of the information
        we hold about you.</p>
      <h6>1.2 Information We Do Not Collect</h6>
      <p>We do not intentionally collect or store special categories of personal data, such as date of birth, genetic
        data, health information, or religious information. Although Suitable.ai does not request or intentionally
        collect any special categories of personal data, we realize that you might store this kind of information in
        your account, via the files that you upload. If you ask us to upload or store any files containing special
        categories of personal data on our servers, located in India, we will only do so on your instructions as a data
        processor and service provider and in accordance with our <a href="terms-condition.html">Terms of
          Service</a> with you. You are the controller of this data and you must ensure that you have a legal basis to
        share this data with us.</p>
      <h6>1.3 How We Use Your Information and our Legal Basis for doing so</h6>
      <p>We use the information, including Personal Information, you provide to us in a number of ways and we have set
        out our legal basis for processing your information below:<br/>
          To provide, update, maintain and improve the Services we offer to you<br/>
          This is the most common usage of your information. We use your Personal Information to provide various talent
          selection Services to you, in accordance with our Terms of Service. In particular, we will use your data to:
          set-up an account; upload the files that you provide us with, to convert them to more user friendly format and
          fulfill and improve task of talent selection and improve usability of Service; and to send you links to access
          and view our service output to the email address you provide us with.<br/>
          Our use of your Personal Information in this way is necessary to perform our obligations to provide the
          Services to you, under our Terms of Service.<br/>
          To communicate with you by responding to your requests, comments, Support enquiries and sales enquiries<br/>
          If you contact our support team we may use the information that you provide to us in order to help us respond
          to your enquiry. Our use of your Personal Information in this way is necessary to perform our customer service
          obligations to you, under our Terms of Service. If we do not have a contract with you, we may process your
          Personal Information for these purposes where it is in our legitimate interests to do so for customer services
          purposes.<br/>
          For billing, account management and other administrative matters<br/>
          Suitable.ai may need to contact you for invoicing, account management and similar reasons and we use account
          data to administer accounts and keep track of billing and payments. Our use of your Personal Information in
          this way is necessary to perform our obligations to you under our Terms of Service. However, your information,
          including Personal Information is accessible to only few authorized personnel within Suitable.ai and they will
          only do so to contact you on any of these matters.<br/>
          As required by applicable law, legal process or regulation<br/>
          We may use your information, including Personal Information, to comply with court orders and similar legal or
          regulatory obligations which apply to us. This may include where we reasonably consider it is in our
          legitimate interests or the legitimate interests of others to comply, as well as where we are legally required
          to do so.<br/>
          To investigate and help prevent abuse or security issues<br/>
          We may use information such as your IP address to help us prevent abuse of the Services we provide and
          investigate any potential unauthorized use of those Services or other security breaches. In these
          circumstances, we believe we have a legitimate interest in handling your data.<br/>
          If we rely on our (or another person’s) legitimate interests for using your Personal Information, we will
          undertake a balancing test to ensure that our (or the other person’s) legitimate interests are not outweighed
          by your interests or fundamental rights and freedoms which require protection of the Personal Information. You
          can ask us for information on this balancing test by using the contact details at section 5.7.<br/>
          We also collect and use data which is aggregated or anonymised for certain business purposes, such as creating
          aggregate statistics or reporting. However, no single individual will be identifiable from the anonymised
          details we collect for these purposes.<br/>
          Information we may have access to<br/>
          Apart from your Personal Information, we do not collect any other information. However, where you upload your
          resumes, CVs or any other documents in relation to a job posting or interview on the Services, we may have
          access to the information contained in such resume, CVs or otherdocuments. You hereby agree and consent to our
          access to such information. You are entitled to delete any such information at your sole discretion.</p>
      <h6>1.4 Information collected inside Suitable.ai from Google.</h6>
      <p>Suitable.ai requires Google authorization to get access to user emails, these emails are scanned to find a
        valid attachment i.e is a potential resume, once the process is completed Suitable.ai only keeps the attachment
        and the email id of the email account used for scanning through email’s, whose permission is granted by the
        user. Suitable.ai does not change any information of the Google account used, nor sends any mail from the
        authorized Google account. Nor does Suitable.ai add or amend the folder or label structure of Gmail. The data
        obtained from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use
        requirements.</p>
      <h6>1.5 Cookies</h6>
      <p>What are cookies?<br/>
        Cookies are small text files (typically made up of letters and numbers) placed in the memory of your browser or
        device when you visit a website orview a message. They allow a website to recognize a particular device or
        browser. Session-based cookies last only while your browser is open and are automatically deleted when you close
        your browser. Persistent cookies last until you or your browser delete them or until they expire. For further
        information on deleting, disabling, blocking and controlling cookies please visit <a
          href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a></p>
      <h6>1.6 Tracking</h6>
      <p>Google Analytics<br/>
        We use Google Analytics as a third party tracking service, but don’t use it to track you individually. We use
        Google Analytics to collect information about how our Website performs and how our users, in general, navigate
        through and use Suitable.ai to help us evaluate our users’ use of Suitable.ai; compile statistical reports on
        activity; and improve our content and Website performance.<br/>
        We look to protect your privacy by anonymizing your IP address prior to sending it to Google Analytics and we
        only gather certain basic information, such as your browser type, referring and exit pages, time stamp, and
        similar data about your use of Suitable.ai. We do not link this information to any of your Personal Information.<br/>
        Google provides further information about its own privacy practices and offers a browser add-on to opt out of
        Google Analytics tracking.<br/>
        Other Tracking<br/>
        We do not allow any third party social media websites (such as Facebook or Twitter) to track you across our
        Services – all of the social media buttons served through our Services are configured to explicitly disallow
        tracking from third parties whilst using our Services.</p>
      <h5>2. HOW WE SHARE DATA</h5>
      <h6>2.1 Sharing Your Information</h6>
      <p>We do not share, sell, rent or trade your email address with any third parties for any commercial purposes.<br/>
        We may share Personal Information with a limited number of third-party vendors who process it on our behalf to
        provide or improve our Services, and who have agreed to privacy restrictions and security obligations consistent
        with this Privacy Policy.<br/>
        We will share your Personal Information with the following third party vendors:<br/>
        – Microsoft Azure Services and Amazon Web Services who provides server hosting and content distribution networks
        (CDN’s) services;<br/>
        – Namecheap Inc who provides domain name services;<br/>
        – SendGrid Inc who provides us email sending services;<br/>
        – Razorpay who provides payment processing services; and- Google Suite and Hubspot, who provide customer
        relationship management and customer service tools.<br/>
        When we transfer your data to our vendors, we remain responsible for it. We try to ensure that any third parties
        with whom we share your Personal Information are limited (by law and by contract) in their ability to use your
        Personal Information for any purpose other than to provide services for us.<br/>
        We may share Personal Information where it is in our legitimate interests to do so to run, grow and develop our
        business if we are involved in a merger, sale, or acquisition. If any such change of ownership happens, we will
        ensure it is under terms that preserve the confidentiality of Personal Information, and we will notify you on
        our Website or by email before any transfer of your Personal Information.</p>
      <h6>2.2 Third Party Advertising</h6>
      <p>We do not host any third party advertising at Suitable.ai. Therefore, whether you access our Service outside or
        inside of European Economic Area(“EEA”), none of personal or user data is shared with any third party
        advertising.</p>
      <h6>2.3 Legal Disclosures</h6>
      <p>Suitable.ai may disclose personally-identifying information or other information we collect about you in
        response to a valid subpoena, court order,warrant, or similar government order, or when we believe in good faith
        that disclosure is reasonably necessary to protect our property or rights, or those of third parties or the
        public at large. This may include exchanging Personal Information with other organisations for the purposes of
        fraud protection and credit risk reduction. We will also disclose your Personal Information to third parties in
        order to enforce or apply our terms and conditions or any other agreement or to respond to any claims, to
        protect our rights or the rights of a third party, to protect the safety of any person or to prevent any illegal
        activity.</p>
      <h5>3. HOW DO WE MANAGE DATA</h5>
      <h6>3.1 Security</h6>
      <p>Suitable.ai takes the security of data very seriously, and we work hard to protect any information you provide
        to us from loss, misuse, and unauthorized access or disclosure. We take all reasonable precautions to safeguard
        the confidentiality of your Personal Information, including through use of appropriate organisational and
        technical measures. These measures take into account the sensitivity of the data we collect, process and store,
        and the current state of technology.<br/>
          Given the nature of communications and information processing technology, Suitable.ai cannot guarantee that
          information, during transmission through the Internet or while stored on our systems or otherwise in our care,
          will be absolutely safe from intrusion by others, but we do our utmost to protect it. Once we have received
          your Personal Information, we will use strict procedures and security features to prevent unauthorised access
          to it.<br/>
          As a minimum we take the following measures to secure your data:<br/>
          – Physical Security – Suitable.ai infrastructure is only hosted in data centres which meet rigorous security
          standards. Physical access is strictly controlled both at the perimeter and at building ingress points by
          professional security staff utilising video surveillance, intrusion detection systems, and other electronic
          means.<br/>
          – Data Transfer Integrity – All traffic to and from Suitable.ai servers is secured by transport level security
          (TLS) sent over a Secure Socket Layer (SSL), and secured using an AES 256-bit SSL certificate. This ensures
          that data sent between your systems and ours is encrypted using military grade encryption.<br/>
          – Password Encryption – Suitable.ai user account passwords are stored in our database after being salted and
          hashed using the encryption algorithms.<br/>
          – Firewalls – Suitable.ai enforces network level control for access to infrastructure by using multiple
          different firewall technologies to ensure that different components of its systems are logically isolated from
          one another.<br/>
          – Operational Access Controls – Suitable.ai employees require access to production services for operational
          reasons. We employ multiple authentication mechanisms to ensure that production systems are accessed only by
          authorized members of staff and are protected from unauthorized access.<br/>
          – Software Updates – Suitable.ai regularly applies software patches to production infrastructure in order to
          ensure a strong security posture to known software vulnerabilities.<br/>
          – Bug Bounty Program – Suitable.ai operates an informal “bug bounty” program that encourages security
          researchers to perform limited and authorized testing of the integrity of Suitable.ai systems.</p>
      <h6>3.2 Data Retention and Deletion</h6>
      <p>If you have an account with Suitable.ai<br/>
        We will retain your Personal Information for as long as your account is active or as needed to provide you with
        Services that you request. Once your account is deactivated we will permanently remove any files associated with
        your account in accordance with our Terms of Service).<br/>
        We may, in Our sole discretion, continue to store data / information such as email and contact information,
        billing and invoice information, payment, late payment or non-payment information or such related information of
        the Customer for record keeping, audit, fraud and reporting purposes in accordance with applicable laws. For
        example, we don’t automatically delete inactive user accounts, so unless you ask us to permanently delete your
        account we will retain some account information to enable you to return and use our Services more easily in
        future.<br/>
        If you do not have an account with Suitable.ai<br/>
        We will store any files you upload or source using the service for trying the Service, and their converted
        format on our systems for no longer than 180 days following upload. After this time they will be permanently
        removed from our storage systems.<br/>
        We will retain Personal Information such as your IP address, email address, contact information and browser
        details for no longer than 180 days. After this time we permanently delete your browser data and anonymise your
        IP address and email address so that we can no longer personally identify you.</p>
      <h6>3.3 International Data Transfers</h6>
      <p>Suitable.ai may transfer your personal data to countries other than the one in which you live, including to our
        hosting servers with reputed suppliers located in the United States and Asia.<br/>
          If we provide any Personal Information about you to any such non-EEA entities, we will take appropriate
          measures to ensure that the recipient protects your Personal Information adequately in accordance with this
          Privacy Policy. These measures may include the following permitted in Articles 45 and 46 of the General Data
          Protection Regulation (“GDPR”):<br/>
          – in the case of US based entities, entering into European Commission approved standard contractual
          arrangements with them, or ensuring they have signed up to the EU-US Privacy Shield (see further
          https://www.privacyshield.gov/welcome); or<br/>
          in the case of entities based in other countries outside the EEA, entering into European Commission approved
          standard contractual arrangements with them.<br/>
          Further details on the steps we take to protect your Personal Information, in these cases is available from us
          on request by contacting us by email at support@suitable.ai at any time.</p>
      <h5>4. YOUR RIGHTS</h5>
      <p>If you have a user account for the Services you may review and edit any personal data you have supplied to us
        in your user profile. If you do not have a user account, or if you have questions about your account information
        or other personal data please contact us by email at support@suitable.ai.<br/>
          Individuals located in certain countries, including the European Economic Area and Switzerland, have certain
          statutory rights in relation to their personal data. While some of these rights apply generally, certain
          rights apply only in certain limited circumstances. We describe these rights below.<br/>
          Please note that we may ask you to verify your identity before taking further action on your request.
          Additionally your request and choices may be limited in certain cases: for example, if fulfilling your request
          would reveal information about another person, or if you ask to delete information which we are permitted by
          law or have compelling legitimate interests to keep.</p>
      <h6>4.1 Right to be Informed</h6>
      <p>You have the right to be provided with information about the data we hold, our data processing activities and
        whether we transfer personal data outside of the EEA, along with the methods we use to safeguard such data.</p>
      <h6>4.2 Right to Access</h6>
      <p>In some jurisdictions, applicable law may entitle you to request copies of your Personal Information held by
        us</p>
      <h6>4.3 Right to Rectification</h6>
      <p>You have the right to ask us to correct inaccurate, out of date or incomplete Personal Information concerning
        you (and which you cannot update yourself within the Services).</p>
      <h6>4.4 Right to Erasure</h6>
      <p>We generally retain any Personal Information for as long as is necessary for the performance of the contract
        between you and us and to comply with our legal obligations. If you no longer want us to use your information to
        provide the Services to you, you can request that we erase your Personal Information and (if you have one) close
        your user account. Please note that if you request the erasure of your Personal Information:<br/>
          – We may retain some of your Personal Information as necessary for our legitimate business interests and for
          identification purposes, and in the interest of prevention of fraud, impersonation, etc..<br/>
          – We may retain and use your Personal Information to the extent necessary to comply with our legal
          obligations.<br/>
          – Because we maintain the Suitable.ai Services to protect from accidental or malicious data loss and
          destruction, residual copies of your Personal Information may not be removed from our backup systems for a
          limited period of time.</p>
      <h6>4.5 Right to Object / Restrict Processing</h6>
      <p>In some jurisdictions, applicable law may entitle you to request Suitable.ai not to process your Personal
        Information for certain specific purposes where such processing is based on our (or another party’s) legitimate
        interests. If you object to such processing Suitable.ai will no longer process your Personal Information for
        these purposes unless we can demonstrate compelling legitimate grounds for such processing or such processing is
        required for the establishment, exercise or defence of legal claims.</p>
      <h6>4.6 Right to Data Portability</h6>
      <p>You may be entitled to request copies of Personal Information that you have provided to us in a structured,
        commonly used, and machine-readable format and/or request us to transmit this information to another service
        provider (where technically feasible). This right only applies where we use your Personal Information on the
        basis of your consent or performance of a contract; and where our use of your information is carried out by
        automated means.</p>
      <h5>5. OTHER IMPORTANT INFORMATION</h5>
      <h6>5.1 Data Processing Addendum</h6>
      <p>AddendumSuitable.ai will be the controller of personal data provided to, or used in connection with our
        Services, as described in this Privacy Policy.To the extent that we act as a data processor on your behalf in
        connection with the performance of our Services, we will enter into a separate “Data Processing Addendum” with
        you. You can find out more information on this Data Processing Addendum by emailing us at
        support@suitable.ai.</p>
      <h6>5.2 Age Limitations</h6>
      <p>Persons who are competent of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible
        to access, use or register on the Website and avail the Services. In the event that, as a minor you wish to use
        the Website and / or Services, such use shall be made available to you upon the review of these Terms by your
        legal guardian or parent(s) and upon them consenting to be bound by the Terms. Further, in the event that it is
        discovered that You are below the age of 18 (eighteen) years and the Terms have not been consented to by your
        legal guardian or parent(s), or if the details provided by you are false or inaccurate, Suitable.ai shall not
        have the responsibility and shall not be held liable if the aforesaid eligibility criteria is not satisfied by
        you.</p>
      <h6>5.3 Changes to This Privacy Policy</h6>
      <p>Suitable.ai reserves the right to amend, modify, add, delete or remove any portion of this Privacy Policy as
        required under any applicable law from time to time. Laws, regulations and industry standards evolve, which may
        make those changes necessary, or we may make changes to our business. We will post the changes to this page and
        update the “last modified date” when we do so. Usage of your Personal Information shall be governed by the last
        updated or revised Privacy Policy which shall be available on the Website. Your continued use / access /
        browsing of the Website and/or Services after any such amendment or modification signifies your acceptance to
        such amendment or modification. We would encourage you to review our Privacy Policy to stay informed. If you
        disagree with the changes to this Privacy Policy, you should deactivate your registered account for the
        Services, or otherwise cease to use / access / browse the Website and / or the Services.</p>
      <h6>5.4 Third Party Links And Third Party Integration</h6>
      <p>The Website / Services may host links to third party websites and services (“Third Party Links”). We have no
        control over such Third Party Links, which are provided by persons or companies other than us. We are not
        responsible for any collection or disclosure of your data / information or Personal Information by such
        companies or persons on such Third Party Links thereof.<br/>
          Certain other features and integrations, including but not limited to infrastructure services, communication
          integrations, user visibility and assessment services, background verification services (“Third Party
          Integrations”) are available to You through third party platforms and forumswhere applications are developed
          for their integration with the Service(s). These Third Party Integrations are governed by their own terms and
          privacy policies and You agree that We are not responsible for Your use of these Third Party Integrations
          where You choose to enable these ThirdParty Integrations and integrate them into Our Service(s). By enabling
          the Third Party Integrations, You understand and agree that We do not provide any warranties in any manner
          whatsoever for Third Party Integrations and We are not liable for any damage or loss caused or alleged to be
          caused by or in connection with Your enablement, access or use of any such Third Party Integrations, or Your
          reliance on the privacy practices, data security processes or any other policies and processes of such Third
          Party Integrations. You understand that We are not responsible for providing technical support for such Third
          Party Integrations and that We are not responsible for the data hosting and data transfer practices followed
          by providers of such Third Party Integrations. To this extent, You shall address any comments, queries,
          complaints or feedback about such Third Party Integrations to the respective developers or publishers as
          specified on such other platforms or forums.<br/>
          You agree and acknowledge that Third Party Links and Third Party Integrations have their own privacy policies
          governing the collection, storage, transfer, retention and / or disclosure of your information and that you
          access or use such Third Party Links and Third Party Integrations at your own risk.</p>
      <h6>5.5 DISCLAIMER</h6>
      <p>Although we are committed to protecting your privacy, we do not make any promise to the effect that your
        information, including Personal Information, or private communications will always remain private or may be
        disclosed in ways not otherwise described in this Privacy Policy. You assume all responsibility and risk for:
        (i) your use of the Website and/ or Services; (ii) use of the internet generally; (iii) the information you
        provide or access; and (iv) your conduct on and off the Website. Further, we assume no liability for any actions
        of third parties with regard to your Information or Personal Information which you may have disclosed to such
        third parties by accessing or visiting Third Party Links or using integrations, apps and custom apps are made
        available to you through the market place or other forums where applications are developed for their integration
        with the Service(s). Suitable.ai shall disclaim any liability arising out of your use of Third Party Links or
        any other dealings with such third parties.</p>
      <h6>5.6 Governing Law</h6>
      <p>This Privacy Policy shall, in all respects, be governed by and construed in all respect in accordance with the
        laws of India. The courts in Hyderabad, India, shall have exclusive jurisdiction in connection with any dispute
        arising out of or in connection with these Terms.</p>
      <h6>5.7 Contacting Suitable.ai</h6>
      <p>Please feel free to contact Suitable.ai if you have any questions about this Privacy Policy or complaints about
        Suitable.ai’s practices relating to your Personal Information, or if you are seeking to exercise any of your
        rights described in this Privacy Policy, you may contact us at support@suitable.ai or at our mailing address
        below:<br/>
          Suitable.ai Data Protection Representative at<br/>
          61, Second Floor, Kailash Hills, East of Kailash<br/>
          Delhi, India, 110065</p>
    </div>
  </div>
}
export default PrivacyPolicy;