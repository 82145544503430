import * as React from "react";
const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={555.999}
        height={25.512}
        viewBox="0 0 555.999 25.512"
        {...props}
    >
      <path
          id="Path_1292"
          data-name="Path 1292"
          d="M0,1S131.177-15,267.687-15,546.039,0,546.039,0"
          transform="translate(5.012 19.5)"
          fill="none"
          stroke="#dbc446"
          strokeLinecap="round"
          strokeWidth={9}
      />
    </svg>
);
export default SVGComponent;