import React from 'react';
import styles from './styles.module.css';
import commonStyles from "../../Common/Styles/commonStyles.module.css";

const BlueSection =  (props) =>
{
  return <section className={styles.requestBlueSection}>
    <div>Join our 2000+ Talent Partner network today!</div>
    <a href={'https://app.suitable.ai/login'} className={commonStyles.mainBtn}>
      Start Earning 💸
    </a>
  </section>
}
export default BlueSection;