import React from 'react';
import styles from './styles.module.css';
import illustration from './assets/404.svg';
import {Link} from "react-router-dom";

export default function (props) {
  return <div className={styles.pageContainer}>
    <img src={illustration} className={styles.illustration} width={359} height={311} alt={'illustration'}/>
    <Link to={'/'} className={styles.btn}>HomePage</Link>
  </div>
}