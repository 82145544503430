import * as React from "react";
const FindUnderlineSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={280.451}
        height={13.876}
        viewBox="0 0 280.451 13.876"
        {...props}
    >
      <path
          id="Path_1296"
          data-name="Path 1296"
          d="M0,1S66.15-3.577,134.435-3.577H271.151"
          transform="translate(4.8 8.077)"
          fill="none"
          stroke="#dbc446"
          strokeLinecap="round"
          strokeWidth={9}
      />
    </svg>
);
export default FindUnderlineSVG;
