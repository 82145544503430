import React from 'react';
import styles from './styles.module.css';
import commonStyles from '../../Common/Styles/commonStyles.module.css';
import classNames from 'classnames';
import nurtureStakeholderValue from './assets/nurtureStakeholderValue.png';
import effortValue from './assets/effortsValue.png'
import experienceValue from './assets/experienceValue.png';
import impactValue from './assets/impaceValue.png';
import greenBg from './assets/greenBg.png';
import whiteBg from './assets/whiteElipsedBg.png';
import {Helmet} from "react-helmet";

const About = props => {

  return <div>
    <Helmet>
      <title>About Us - Suitable AI</title>
      <link href={window.location.href} rel="canonical"/>
    </Helmet>
    <div className={styles.aboutPageContainer}>
      <section className={styles.firstSection}>
        <div className={styles.firstSectionHeading}>
          Talent Sourcing <br/>
          for the <span className={styles.firstSectionHeadingHighlight}>Future!</span>
        </div>
        <div className={styles.firstSectionSubHeading}>
          On a mission to create 10x opportunities
        </div>
      </section>
      <section className={styles.ourMissionSection} style={{backgroundImage: `url(${whiteBg})`}}>
        <div className={classNames('container-fluid', commonStyles.pageContainerWidth)}>
          <div className={'row'}>
            <div className={'col-md-5'}>
              <div className={'row'}>
                <div className={styles.aboutSectionHeading}>
                  What we aim
                </div>
                <div className={'col'}>
                  <div className={styles.aboutSectionSecondHeading}>
                    Our Mission
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-md-7'}>
              <div className={styles.rightSideTextContainer}>
                Suitable AI is dedicated to addressing the rising difficulty of talent sourcing by developing a system that nurtures top recruiters and enables companies to secure the most skilled individuals quickly.
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<section className={styles.awardsContainer}>*/}
      {/*  <div className={classNames('container-fluid', commonStyles.pageContainerWidth)}>*/}
      {/*    <div className={'row'}>*/}
      {/*      <div className={'col-12'}>*/}
      {/*        <div className={styles.aboutSectionHeading}>Awards</div>*/}
      {/*      </div>*/}
      {/*      <div className={'col-12'}>*/}
      {/*        <div className={styles.aboutSectionSecondHeading}>*/}
      {/*          Creating Buzz in the Industry*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={'row'}>*/}
      {/*      <div className={'col-12'}>*/}
      {/*        <div className={commonStyles.pageContainerWidth}>*/}
      {/*          <div className={styles.awardsCardsContainer}>*/}
      {/*            <div className={styles.awardsSectionCards}>*/}
      {/*              <img loading="lazy" className={styles.awardsCardImg} alt='company-logo' src={timesBusiness} width={180} height={180}/>*/}
      {/*              <div className={styles.awardsCardTextContent}>*/}
      {/*                Best Ecosystem Solution in the Restaurant Industry*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.awardsSectionCards}>*/}
      {/*              <img loading="lazy" className={styles.awardsCardImg} alt='company-logo' src={startUpCity} width={180} height={180}/>*/}
      {/*              <div className={styles.awardsCardTextContent}>*/}
      {/*                Best Startup to Work for in 2022*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.awardsSectionCards}>*/}
      {/*              <img loading="lazy" className={styles.awardsCardImg} alt='company-logo' src={indianAchievers} width={180}*/}
      {/*                   height={180}/>*/}
      {/*              <div className={styles.awardsCardTextContent}>*/}
      {/*                Emerging Company of the year 2022*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.awardsSectionCards}>*/}
      {/*              <img loading="lazy" className={styles.awardsCardImg} alt='company-logo' src={globalHues} width={180} height={180}/>*/}
      {/*              <div className={styles.awardsCardTextContent}>*/}
      {/*                Restaurant Operation System*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className={styles.ourValuesContainer}>
        <div className={classNames('container-fluid', commonStyles.pageContainerWidth, styles.ourValuesContainerInner)}>
          <div className={'row'}>
            <div className={styles.aboutSectionHeading}>
              Our Values
            </div>
            <div className={styles.aboutSectionSecondHeading}>
              What do we believe at Suitable AI?
            </div>
          </div>
          <div className={styles.valuesCardContainer}>
            <div className={styles.valuesCard}>
              <img loading="lazy" alt="logoImg" className={styles.valueCardImg} src={nurtureStakeholderValue} width={80} height={80}/>
              <div className={styles.valueCardContentContainer}>
                <div className={styles.valueCardContentHeading}>
                  Nurture each stakeholder
                </div>
                <div className={styles.valueCardContentText}>
                  We empathize with every stakeholder involved in recruitment, understand and act accordingly.
                </div>
              </div>
            </div>
            <div className={styles.valuesCard}>
              <img loading="lazy" alt="logoImg" src={effortValue} className={styles.valueCardImg} width={80} height={80}/>
              <div className={styles.valueCardContentContainer}>
                <div className={styles.valueCardContentHeading}>
                  Efforts not excuses

                </div>
                <div className={styles.valueCardContentText}>
                  We dedicate ourselves to building a sustainable business for all our partners and aid in their professional growth.
                </div>
              </div>
            </div>
            <div className={styles.valuesCard}>
              <img loading="lazy" alt="logoImg" className={styles.valueCardImg} src={impactValue} width={80} height={80}/>
              <div className={styles.valueCardContentContainer}>
                <div className={styles.valueCardContentHeading}>
                  Make positive impact
                </div>
                <div className={styles.valueCardContentText}>
                  Our passion solely lies in positively disrupting the recruitment industry and making an everlasting impact.
                </div>
              </div>
            </div>
            <div className={styles.valuesCard}>
              <img loading="lazy" alt="logoImg" className={styles.valueCardImg} src={experienceValue} width={80} height={80}/>
              <div className={styles.valueCardContentContainer}>
                <div className={styles.valueCardContentHeading}>
                  Experience is key
                </div>
                <div className={styles.valueCardContentText}>
                  Everything we do has one focus - improve our user's experience, because for us experience is everything.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<section className={styles.investorsSection}>*/}
      {/*  <div className={classNames(commonStyles.pageContainerWidth, styles.investorSectionInner)}>*/}
      {/*    <div className={'container-fluid'}>*/}
      {/*      <div className={'row'}>*/}
      {/*        <div className={'col-12'}>*/}
      {/*          <div className={styles.aboutSectionHeading}>Investors</div>*/}
      {/*          <div className={styles.aboutSectionSecondHeading}>*/}
      {/*            People who believe in our vision*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={'row'}>*/}
      {/*        <div className={'col-12'}>*/}
      {/*          <div className={styles.investorsCardContainer}>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Y Combinator*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                The world's Most Sought After Startup Accelerator*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Kevin Lin*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Co-Founder, Twitch*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                HOF Capital*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                NY Based VC Fund*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Sajid Rehman*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Founder, MyAsiaVC*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Taher Savliwala*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Co-founder, Relief Technologies.inc*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Aniketh Jain*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Co-Founder, Solution Infini, Ex-CRO, Kaleyra*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Zentani Capital*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Serial Entrepreneurs Backing Ambitious Startups*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.investorsCard}>*/}
      {/*              <img loading="lazy" src={yCombinator} width={180} height={180} alt="investor logo/image" />*/}
      {/*              <div className={styles.investorName}>*/}
      {/*                Smaragi*/}
      {/*              </div>*/}
      {/*              <div className={styles.investorDetails}>*/}
      {/*                Japan's Largest POS Company*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section className={styles.mediaCoverage}>*/}
      {/*  <div className={classNames(commonStyles.pageContainerWidth, styles.mediaCoverageInner)}>*/}
      {/*    <div className={'container-fluid'}>*/}
      {/*      <div className={'row'}>*/}
      {/*        <div className={styles.aboutSectionHeading}>*/}
      {/*          Media Coverage*/}
      {/*        </div>*/}
      {/*        <div className={styles.aboutSectionSecondHeading}>*/}
      {/*          We keep hitting headlines often*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={'row'}>*/}
      {/*        <div className={'col-12'}>*/}
      {/*          <div className={styles.mediaCoverageCardsContainer}>*/}
      {/*            <div className={styles.mediaCoverageCard}>*/}
      {/*              <div className={styles.mediaCoverageCardImgSection}>*/}
      {/*                <img className={styles.mediaCoverageCardImg} src={mediaCoverageImg} alt={'photo of article'} width={'100%'} height={'100%'} />*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardTextSection}>*/}
      {/*                All-in-one integrated digital platform SuitaleAI makes restaurant operations seamless*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardMarker}>*/}
      {/*                ET HOSPITALITY WORLD*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.mediaCoverageCard}>*/}
      {/*              <div className={styles.mediaCoverageCardImgSection}>*/}
      {/*                <img className={styles.mediaCoverageCardImg} src={mediaCoverageImg} alt={'photo of article'} width={'100%'} height={'100%'} />*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardTextSection}>*/}
      {/*                YC Backed SuitaleAI Bags $5Mn Funding To Help Restaurants Digitize Operations*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardMarker}>*/}
      {/*                INC 42*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className={styles.mediaCoverageCard}>*/}
      {/*              <div className={styles.mediaCoverageCardImgSection}>*/}
      {/*                <img className={styles.mediaCoverageCardImg} src={mediaCoverageImg} alt={'photo of article'} width={'100%'} height={'100%'} />*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardTextSection}>*/}
      {/*                30 Startups To Watch: The Startups That Caught Our Eye In July 2022*/}
      {/*              </div>*/}
      {/*              <div className={styles.mediaCoverageCardMarker}>*/}
      {/*                INC 42*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className={styles.callToAction} style={{backgroundImage: `url(${greenBg})`}}>
        <div className={commonStyles.pageContainerWidth}>
          <div className={'container-fluid'}>
            <div className={'row'}>
              <div className={'col'}>
                <div className={styles.firstSectionSubHeading}>
                  Join our 2000+ Talent Partner network today!
                </div>
                <div className={styles.firstSectionHeading}>
                  10x your income with Suitable AI
                </div>
                <div className={styles.callToActionBtnContainer}>
                  <a
                      href="https://app.suitable.ai/login"
                      target='_blank'
                      rel="noreferrer"
                      className={styles.callToActionBtn}
                  >
                    Start Earning 💸
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
};
export default About;