import React from 'react';
import styles from './styles.module.css';

const TermsCondition = props => {

  return <div className={styles.pageContainer}>
    <div className="container">
      <h4>Terms &amp; Condition</h4>
      <p>By accessing or using the Service(s) or Website or authorizing or permitting any User or End-User to access or
        use the Service(s) or Website,You agree to be bound by these Terms.<br/>
    <span>Effective Date | 10th March 2021<br/> <br/>
    Last date Modified | 10th March 2021</span></p>
      <p></p>
      <p>Please read these terms of service (“Terms of Service”)and Our Privacy Policy (together, these “Terms”)
        carefully as they form a contract between the Customer, Users, End-Users or Candidates (“You”) and Us and govern
        use of and access to the Service(s) and Websites byYou, Your Affiliates, Users and End-Users. In the event of a
        conflict between these Terms of Service and Our Privacy Policy, these Terms of Service shall prevail. <span>By accessing or using the Service(s) or Website or authorizing or permitting any User or End-User to access or use the Service(s) or Website,You agree to be bound by these Terms. If You are entering into these Terms on behalf of a company,organization or another legal entity(an “Entity”), You are agreeing to these Terms for that Entity and representing to Us that You have the authority to bind such Entity and its Affiliates to these Terms, in which case the terms,“You“, “Your” or related capitalized terms used here inshall refer to such Entity and its Affiliates. If You do not have such authority, or if You do not agree with these Terms, You must not accept these Terms and may not access or use the Service(s) or Websites.</span>
        <span>You, as an individual, must be 18 years or older to access or use the Websites and the Service(s). Persons who are competent of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to access, use or registeron the Website and avail the Service(s). In the event that, as a minor You wish to use the Website and / or Services, such use shall be made available to You upon the review of these Terms by Your legal guardian or parent(s) andupon them consenting to be bound by the Terms contained herein. Further, in the event that it is discovered that You are below the age of 18 (eighteen)years and the Terms have not been consented to by your legal guardian or parent(s),or if the details provided by You are false or inaccurate, Suitable shall not have the responsibility and shall not be held liable if the aforesaid eligibility criteria is not satisfied by You.</span>
      </p>
      <h5>1. YOUR RIGHTS</h5>
      <h6>1.1 Introduction</h6>
      <p>These Terms are applicable during Your free trial and during Your subscription to the Service(s)through a
        Subscription Plan of Your choice.</p>
      <h6>1.2 Using Our Service(s):</h6>
      <p>Subject to Your compliance with the Terms and solely during the Subscription Term, You have the limited,
        non-exclusive, and revocable right to access and use the Service(s) for Your internal business purposes. You
        shall be responsible for use of the Service(s) through Your Account by any third parties. You may subscribe to
        one or more of the Service(s). They may be subject to separate and distinct Subscription Plans.</p>
      <h6>1.3 Using our APIs:</h6>
      <p>Where applicable, our APIs must be used according to the API Policies We implement in this regard.</p>
      <h5>2. YOUR RESPONSIBILITIES</h5>
      <h6>2.1 Your Account:</h6>
      <p>Subject to any limitation on the number of individual Users available under the Service Plan to which You
        subscribed, access and use of the Service(s) is restricted to the specified number of individual Users permitted
        under Your subscriptionto the Service(s). Each User shall beidentified using unique login information such as
        usernames and passwords (“User Login”) and such User Login shall be used only by one individual. If You are a
        managed service providerand You wish to use the same User Login across Accounts that You manage for Your
        clients,You acknowledge that it is Your sole responsibility to obtain necessary consents from such clients.
        Without prejudice to Our obligations under Sections 9 and 10 of these Terms, You aresolely responsible for the
        confidentiality of Service Data and User Login at Your end. You should, therefore, not share Your User Login
        withany third parties. In any event, unless You notify Us of any unauthorized use or suspicious activity in Your
        Account, You are responsible for all activities that occur under Your Account. Suitable will not be liable for
        any damage or loss that may result from Your failure to protect Your login information, including Your password.
        Without limiting the foregoing, You are solely responsible for ensuring that Your use of the Service(s) to store
        and transmit Service Data is compliant with all applicable laws and regulations. You also maintain all
        responsibility for determining whether the Service(s) or the information generated thereby is accurate or
        sufficient for Your purposes.</p>
      <h6>2.2 Your use of the Service(s):</h6>
      <p>You agree not to (a) license, sublicense, sell, resell,rent, lease, transfer, assign, distribute, timeshare or
        otherwise commercially exploit or make theService(s) available to any third party, otherthan Users and End-Users
        in furtherance of Your internalbusiness purposes as expresslypermitted by these Terms; (b) use the Service(s)
        toProcess data on behalf of any third partyother than Your Users and End-Users; (c) modify, adapt,or hack the
        Service(s) or otherwiseattempt to gain or gain unauthorized access to theService(s) or related systems or
        networks;(d) falsely imply any sponsorship or association withUs; (e) use the Service(s) in any unlawfulmanner,
        including but not limited to violation ofany person’s privacy rights; (f) use the Service(s)to send unsolicited
        communications junk mail, spam,pyramid schemes or other forms ofduplicative or unsolicited messages; (g) use the
        Service(s)to store or transmit any content thatinfringes upon any person’s intellectual propertyrights; (h) use
        the Service(s) in any manner thatinterferes with or disrupts the integrity or performanceof the Service(s) and
        its components; (i)attempt to decipher, decompile, reverse engineer,disassemble, reproduce, or copy or
        otherwiseaccess or discover the source code or underlying programof any Software making up theService(s); (j)
        use the Service(s) to knowingly post,transmit, upload, link to, send or store anycontent that is unlawful,
        racist, hateful, abusive,libelous, obscene, or discriminatory; (k) use theService(s) to store or transmit any
        “protected healthinformation” as that term is defined in 45C.F.R. 160.103 or any health data or health
        informationas that term is defined under ApplicableData Protection Law, unless expressly agreed to otherwisein
        writing by Us; (l) use theService(s) to knowingly post, transmit, upload, linkto, send or store any viruses,
        malware, trojanhorses, time bombs, or any other similar harmful software(“Malicious Software”); (m) establish
        alink to Our Websites in such a way as to suggest anyform of association, approval or endorsement on Our part
        where none exists; (n) use the Service(s) for the purposes of cookietracking, ad exchanges, ad networks, data
        brokerages,or sending electronic communications(including e-mail) in violation of applicable law;(o) use of the
        Service(s) for any purposeprohibited by applicable export laws and regulations,including without limitation,
        nuclear,chemical, or biological weapons proliferation, ordevelopment of missile technology; (p) try touse, or
        use the Service(s) in violation of these Terms.</p>
      <h6>2.3 Loss of data</h6>
      <p>You shall be responsible for any loss of data or attemptedor actual access or use of theService(s) through Your
        Account in violation of theseTerms.</p>
      <h6>2.4 Cease of usage</h6>
      <p>If We inform You that a specified activity or purposeis prohibited with respect to the Service(s),You will
        ensure that You immediately cease use ofthe Service(s) for such prohibited activity orpurpose.</p>
      <h5>3. ACCESS TO THE SERVICE(S)</h5>
      <h6>3.1 Unavailability of services</h6>
      <p>You may not be able to access or use the Service(s)(a) during planned downtime for upgradesand maintenance to
        the Service(s) (of which We willuse commercially reasonable efforts tonotify You in advance through Our
        Service(s)) (“PlannedDowntime”), or (b) during anyunavailability caused by circumstances beyond Ourreasonable
        control, such as, but not limitedto, acts of God, acts of government, acts of terroror civil unrest, technical
        failures beyond Ourreasonable control (including, without limitation,inability to access the internet), or
        actsundertaken by third parties, including without limitation,distributed denial of service attacks.</p>
      <h6>3.2 Downtime periods</h6>
      <p> We will use commercially reasonable efforts to schedulePlanned Downtime for weekends andother off-peak
        hours.</p>
      <h5>4. CHANGES TO THE SERVICE(S) AND WEBSITES</h5>
      <h6>4.1 Our Service(s):</h6>
      <p>We may update the Service(s) from time to time andYou may receive notifications of suchupgrades, enhancements
        or updates (“Updates”). Anynew or modified features added to oraugmenting or otherwise modifying the Service(s)
        orother updates, modifications orenhancements to the Service(s) are also subject tothese Terms and We reserve
        the right todeploy Updates at any time.</p>
      <h6>4.2 Websites:</h6>
      <p>We may also change content on Our Websites at anytime. However, please note that any of thecontent on Our
        Websites may be out of date at anygiven time, and We are under no obligationto update it. For clarity, this
        sub-section refersto Our Websites excluding the Service(s). Wemay discontinue or change any part of Our
        Websites,that does not affect the Service(s), withoutnotifying You. Our Websites may contain links to
        websites,content and resources provided bythird parties (“Third Party Links”). These Third PartyLinks are
        governed by their own terms andprivacy policies and You agree that We have no controlover these Third Party
        Links and are notresponsible for Your access or use of these ThirdParty Links.</p>
      <h6>4.3 Improvements:</h6>
      <p>We may access and process the Service Data to modify,upgrade and enhance Our Service(s),Website and any other
        features or services, includingnut not limited to the algorithm of the Service(s) or Website, any related
        software, codes, APIs, user interface, designs, etc., to ensurethe quality of Your user experience and to
        serviceYou in a better manner. We do not use ordownload the Service Data uploaded by You at any timefor Our own
        use.</p>
      <h5>5. INTELLECTUAL PROPERTY RIGHTS</h5>
      <h6>5.1 Ownership of IPR:</h6>
      <p>Except for the rights granted to You under Section1, all rights, title and interest in and to all Ourpatents,
        inventions, copyrights, trademarks, domainnames, trade secrets, know-how and anyother intellectual property
        and/or proprietary rightsin or related to the Service(s), including theWebsites, and any part of it
        (collectively, “IntellectualProperty Rights”) shall belong to andremain exclusively with Us. We are the owner or
        thelicensee of all Intellectual Property Rightsin relation to the Service(s) and Our Websites, andthe content or
        material published on it. OurIntellectual Property Rights in relation to the Service(s)and Our Websites, and the
        content ormaterial published on it are protected by intellectualproperty laws, including but not limited
        to,copyright laws and treaties around the world. Youmust not use any part of the content ormaterial in the
        Service(s) or on Our Websites forcommercial purposes without obtaining alicense to do so from Us. Further, We
        claim no intellectualproperty rights over the content Youupload or provide in relation to Your Use of the
        Service(s).</p>
      <h6>5.2 Grant of License to Us:</h6>
      <p>We shall have a royalty-free, worldwide, transferable,sub-licensable, irrevocable and perpetuallicense to
        incorporate into the Service(s) or Websitesor otherwise use any suggestions,enhancement requests,
        recommendations or other feedbackWe receive from You.</p>
      <h6>5.3 Grant of License to You:</h6>
      <p>Our product and service names, and logos used or displayedon the Service(s) or Websites areOur registered or
        unregistered trademarks (collectively,“Marks”), and You may only use suchMarks to identify Yourself as a user of
        the Service(s)You have subscribed to.</p>
      <h6>5.4 Reservation of Rights:</h6>
      <p>All rights not expressly provided to You herein arereserved.</p>
      <h5>6. THIRD PARTY INTEGRATIONS</h5>
      <p>Certain other features and integrations, includingbut not limited to infrastructure services,communication
        integrations, user visibility, socialmedia hooks, job vacancy posting on jobboards, assessment services,
        background verificationservices (“Third Party Integrations”) areavailable to You through third-party platforms
        andforums where applications are developed fortheir integration with the Service(s). These ThirdParty
        Integrations are governed by their ownterms and privacy policies and You agree that We arenot responsible for
        Your use of theseThird Party Integrations where You choose to enablethese Third Party Integrations andintegrate
        them into Our Service(s). By enabling theThird Party Integrations, You understandand agree that We do not
        provide any warranties inany manner whatsoever for Third PartyIntegrations and We are not liable for any
        damageor loss caused or alleged to be caused by orin connection with Your enablement, access or useof any such
        Third Party Integrations, or Yourreliance on the privacy practices, data security processesor any other policies
        and processes ofsuch Third Party Integrations. You understand thatWe are not responsible for providingtechnical
        support for such Third Party Integrationsand that We are not responsible for the datahosting and data transfer
        practices followed by providersof such Third Party Integrations. Tothis extent, You shall address any comments,
        queries,complaints or feedback about such Third Party Integrations to the respective developers or publishers as
        specified on such otherplatforms or forums.</p>
      <h5>7.BILLING, PLAN MODIFICATIONS AND PAYMENTS</h5>
      <h6>7.1 Subscription Charges:</h6>
      <p>Unless otherwise specified in the Supplementary Terms,except during Your free trial, allcharges associated with
        Your Account (“SubscriptionCharges”) are due in full and payable inadvance, in accordance with Section 7.2, when
        Yousubscribe to the Service(s). Unlessspecified otherwise in a Form, the Subscription Chargesare based on the
        Subscription PlansYou choose and are payable in full until You terminateYour Account in accordance with
        Section8. You will receive a receipt upon each receipt ofpayment by Us. You may also obtain apayment receipt
        from within the Service(s).</p>
      <h6>7.2 Payment methods:</h6>
      <p>You may pay the Subscription Charges through Yourcredit card, or other accepted paymentsmethod as specified in
        a Form. For credit card payments,Your payment is due immediately upon Your receipt of Our invoice. You hereby
        authorizeUs or Our authorized agents, asapplicable, to bill Your credit card upon Your subscriptionto the
        Service(s) (and any renewalthereof). For payments through other accepted methods,Your payment is due within 15
        (fifteen)days of Our invoice date (“Due Date”) unless otherwisestated in a Form.</p>
      <h6>7.3 Renewal</h6>
      <p>Your subscription to the Service(s) will renew automatically for a Subscription Term equivalent inlength to the
        then expiring Subscription Term. Unlessotherwise provided for in any Form, theSubscription Charges applicable to
        Your subscriptionto the Service(s) for any such subsequentSubscription Term shall be Our standard
        SubscriptionCharges for the Subscription Plan towhich You have subscribed as of the time such
        subsequentSubscription Term commences. You acknowledge and agree that, unless You terminate YourAccount in
        accordance with Section 8,Your credit card/ payment method will be charged automatically for the applicable
        Subscription Charges.</p>
      <h6>7.4 Third-party privacy</h6>
      <p>We may use a third party service provider to managecredit card and other payment processing;provided, that such
        service provider is not permittedto store, retain or use Your paymentaccount information except to process Your
        creditcard and other payment information for Us.You must notify Us of any change in Your credit cardor other
        payment account information,either by updating Your Account or by e-mailing us at support@suitable.ai</p>
      <h6>7.5 Refunds</h6>
      <p>Unless otherwise specified in these Terms or a Formor a Subscription Plan, all SubscriptionCharges are
        nonrefundable. No refunds shall be issuedfor partial use or non-use of theService(s) by You provided however You
        shall be eligiblefor a pro-rated refund of theSubscription Charges for the remainder of the SubscriptionTerm if
        You terminate Your Accountas a result of a material breach of these Terms byUs.</p>
      <h6>7.6. Late Payments/Non-payment of Subscription Charges:</h6>
      <p>We will notify You if We do not receive a paymenttowards the Subscription Charges within theDue Date for Your
        Account (“Payment Notice”). Forpayments made through credit cards, Wemust receive payments due within a maximum
        of five(5) days from the date of Our PaymentNotice and for payments through other accepted methods,We must
        receive payments within a maximum of fifteen (15) days from the date of Our Payment Notice. If We do not
        receivepayment within the foregoing time period, in additionto Our right to other remedies availableunder law,
        We may (i) charge interest for late paymentat the rate of 1.5% per month on theinvoiced amounts and/or; (ii)
        suspend Your accessto and use of the Service(s) until We receiveYour payment towards the Subscription Charges as
        specifiedherein and/or; (iii) terminate YourAccount in accordance with Section 8.2.</p>
      <h6>7.7 Upgrades and Downgrades:</h6>
      <p>You may upgrade Your Account or downgrade within aSubscription Plan or between twoSubscription Plans in
        accordance with the provisionsbelow.</p>
      <h6>7.7.1 Upgrades:</h6>
      <p>You may upgrade Your Account at any time during YourSubscription Term. When You upgradethe new Subscription
        Charges become immediately applicableand the new SubscriptionCharges for the subsisting month would be chargedon
        a pro-rated basis and Your credit card/payment method will be charged automatically. Subsequentmonths will be
        charged in fullaccording to the new Subscription Charges.</p>
      <h6>7.7.2 Downgrades:</h6>
      <p>If You subscribed to the Service(s) before the EffectiveDate, You may downgrade Your Accountat any time during
        Your Subscription Term but beforeits renewal in accordance with Section 7.3above. However, if You choose to
        modify Your SubscriptionTerm after the Effective Date, Youwill not be able to downgrade Your Account duringYour
        renewed Subscription Term as providedherein earlier. You agree that all downgrades in accordancewith this
        Section 7.7.2 will beapplicable only from the subsequent Subscription Term.If You plan to downgrade Your
        Account,please provide Us a notice by writing to billing@suitable.ai at least seven (7) business days priorto
        the expiry of Your current Subscription Term. Youunderstand that downgrading Your Accountmay cause loss of
        content, features, or capacity ofthe Service(s). You agree that We will not beliable for any loss due to such
        downgrading of YourAccount. You must ensure that You alwaysopt for the Subscription Plan that suits Your
        businessrequirements.</p>
      <h6>7.8 Applicable Taxes:</h6>
      <p>Unless otherwise stated, the Subscription Chargesdo not include any taxes, levies, duties orsimilar
        governmental assessments, including value-added,sales, use or withholding taxesassessable by any local, state,
        provincial or foreignjurisdiction (collectively “Taxes”). You areresponsible for paying the Taxes that would be
        leviedagainst You by government authorities.We will invoice You for such Taxes if We believe Wehave a legal
        obligation to do so and Youagree to pay such Taxes if so invoiced.</p>
      <h6>7.9 User Benefits:</h6>
      <p>We may, at Our sole discretion, offer You certainbenefits such as discounts on SubscriptionCharges, extension
        in Subscription Term for no extrapayments from You, with regard to theService(s). These benefits are specific to
        Your Accountand the Service(s) identified whileoffering these benefits. They are not transferrable.The benefits
        may have an expiry date. If theydo not have an expiry date, they will expire uponcompletion of twelve (12)
        months from theirdate of the offer.</p>
      <h5>8. SUSPENSION AND TERMINATION</h5>
      <h6>8.1 Suspension liability</h6>
      <p>We shall not be liable to You or any other third party for suspension or termination of YourAccount, or access
        to and use of the Service(s), if such suspension or termination is inaccordance with these Terms.</p>
      <h6>8.2 Free trial termination policy</h6>
      <p>If You are on a free trial for any of Our Service(s),Your Account may be suspended orterminated in the
        following manner:</p>
      <h6>8.2.1 Violation of terms</h6>
      <p>We may suspend Your access to and use of Your Accountor the Service(s) if You are inviolation of the Terms. We
        will notify You of Youractivities that violate these Terms and, at Oursole discretion, provide You with a period
        of fifteen(15) days (“Free Trial Cure Period”) to cureor cease such activities. If You do not cure or ceasesuch
        activities within said Free Trial CurePeriod or if We believe that Your breach of theseTerms cannot be cured,
        Your Account shall beterminated and all associated Service Data shall bedeleted immediately and permanently.</p>
      <h6>8.2.2 Terminated by You</h6>
      <p>You may terminate Your Account at any time on or beforethe expiry of Your free trial. In suchcases, any
        associated Service Data shall be retainedfor a period of 15 days from the date oftermination of Your Account
        beyond which it shallbe deleted during the normal course ofoperation.</p>
      <h6>8.2.3 Inactivity</h6>
      <p>Where you do not terminate Your Account or renew YourAccount on or before the expiry ofYour free trial, We may
        suspend Your Account. We shallretain any associated Service Data fora period of 6 months beyond which Your
        Account shallbe terminated and all associated ServiceData shall be deleted immediately and permanently.</p>
      <h6>8.3 Customers on a Service Plan:</h6>
      <p>If You are on a Subscription Plan for any of Our Service(s),Your Account may be suspended orterminated in the
        following manner:</p>
      <h6>8.3.1 Violation of terms</h6>
      <p>In addition to suspension for late payment or non-paymentof Subscription Charges, We maysuspend Your access to
        and use of Your Account orthe Service(s) if You are in violation of theTerms. We will notify You of Your
        activities thatviolate these Terms and, at Our sole discretion,provide You with a period of fifteen (15) days
        (“SubscriptionPlan Cure Period”) to cure or ceasesuch activities. If You do not cure or cease suchactivities
        within said Subscription Plan CurePeriod or if We believe that Your breach of theseTerms cannot be cured, Your
        Account shall beterminated. Any associated Service Data shall be retainedfor a period of 15 days from the dateof
        termination of Your Account beyond which it shallbe deleted during the normal course ofoperation.</p>
      <h6>8.3.2 Terminated by You</h6>
      <p>You may elect to terminate Your Account at any timefrom within Our Service(s), if You pay forYour Account
        through credit card. If payment for YourAccount is made through other acceptedpayment methods as specified in
        the Form, You mayrequest to terminate Your Account bywriting to support@suitable.ai . Any associated ServiceData
        shall be retained for a period of 15days from the date of termination of Your Accountbeyond which it shall be
        deleted during thenormal course of operation.</p>
      <h6>8.3.3 Inactivity</h6>
      <p>We may suspend Your Account upon expiry or non-renewal of Your Subscription Term. We shallretain any associated
        Service Data for a period of6 months beyond which Your Account shallbe terminated and all associated Service
        Data shallbe deleted immediately and permanently.</p>
      <h5>8.4 Effect of Terminating Your Account:</h5>
      <h6>8.4.1 Data Export:</h6>
      <p>We strongly recommend that You export all ServiceData before You terminate Your Account. Inany event, following
        the termination of Your Accounteither by You or Us, unless otherwisespecified elsewhere herein or in the
        SupplementalTerms, Service Data will be retained ordeleted in accordance with Sections 8.2 or 8.3 asapplicable
        to You. Where the Service Data isretained as described herein, You may contact Us withinsuch data retention
        period to exportYour Service Data. Service Data cannot be recoveredonce it is deleted. Further, when ServiceData
        is migrated from one data center to another uponYour request, We shall delete ServiceData from the original data
        center after 15 days fromsuch migration.</p>
      <h6>8.4.2 Charges:</h6>
      <p>If Your Account is terminated in accordance with Sections8.2 or 8.3 of these Terms, in additionto other amounts
        You may owe Us, You must immediatelypay any then unpaid SubscriptionCharges associated with the remainder of
        such SubscriptionTerm, unless waived by Us inwriting. This amount will not be payable by You, orYou may be
        eligible for a pro-rated refund ofthe Subscription Charges, as the case may be, whereYou terminate Your
        subscription to theService(s) or terminate Your Account as a result ofa material breach of these Terms by
        Us,provided that You provide notice of such breach toUs and afford Us not less than thirty (30)days to
        reasonably cure such breach.</p>
      <h5>9. CONFIDENTIALITY</h5>
      <h6>9.1 Incompliance</h6>
      <p>If You choose, or You are provided with, a user identificationcode, password or any other pieceof information
        as part of Our security procedures,You must treat such information asconfidential. You must not disclose it to
        any thirdparty. We have the right to disable any useridentification code or password, whether chosen byYou or
        allocated by Us, at any time, if in Ourreasonable opinion, You have failed to comply withany of the provisions
        of these Terms.</p>
      <h6>9.2 Confidentiality obligations:</h6>
      <p>Each of us will protect the other’s Confidential Informationfrom unauthorized use, access ordisclosure in the
        same manner as each of us protectsour own Confidential Information, and inany event, no less than reasonable
        care. Except asotherwise expressly permitted pursuant tothese Terms, each of us may use the other’s
        ConfidentialInformation solely to exercise ourrespective rights and perform our respective obligationsunder
        these Terms and shall disclosesuch Confidential Information solely to those of ourrespective employees,
        representatives andagents who have a need to know such Confidential Informationfor such purposes and who
        arebound to maintain the confidentiality of, and notmisuse, such Confidential Information. Theprovisions of this
        sub-section shall supersede anynon-disclosure agreement by and betweenYou and Us entered prior to these Terms
        that wouldpurport to address the confidentiality ofService Data and such agreement shall have no furtherforce or
        effect with respect to ServiceData.</p>
      <h5>10. DATA PRIVACY AND SECURITY</h5>
      <h6>10.1 Security of Service Data:</h6>
      <p>We use appropriate technical and organizational measures to protect the Service Data that weProcess. The
        measures we use are designed to providea level of security appropriate to therisk of Processing your Service
        Data.</p>
      <h6>10.2 Disclosure</h6>
      <p>You understand that We shall Process Service Datain accordance with Applicable DataProtection Laws and the Data
        Processing Addendum whichare incorporated into these Termsby reference and in accordance with Our Privacy
        Policy.You acknowledge and agree thatSuitable may also access or disclose information aboutYou, Your Account,
        Users or End-Users,including Service Data, in order to (a) comply withthe law or respond to lawful requests or
        legalprocess; (b) protect Suitable’s or Our customers’or partners’ rights or property, includingenforcement of
        these Terms or other policies associatedwith the Service(s); (c) act on a goodfaith belief that such disclosure
        is necessary toprotect personal safety or avoid violation ofapplicable law or regulation. Further, at Our
        solediscretion, any suspected fraudulent, abusive,or illegal activity by You may be referred to lawenforcement
        authorities</p>
      <h5>11. DATA MIGRATION</h5>
      <p>During Your Subscription Term, You may request Usto import data into Your Account (“DataMigration“). You hereby
        understand, agree and acknowledgethat We may access and processYour data in connection with providing You
        supportduring such Data Migration.</p>
      <h5>12. COMMUNICATIONS FROM US</h5>
      <p>Apart from the communications specified in Our PrivacyPolicy, We may contact You directly viae-mail to notify
        You that:<br/>
          1. You are in violation of these Terms;<br/>
          2. A specific activity or purpose is prohibited withrespect to the Service(s), so that Youimmediately cease
          use of the Service(s) for such prohibitedactivity or purpose; or<br/>
          3. You maintain an exceptionally high number of Users,an unusually high monthly ticket ratioper Users, an
          unusually high level of open ticketsor other excessive stress on the Service(s).</p>
      <h5>13. DISCLAIMER OF WARRANTIES</h5>
      <p>The websites and the service(s), including all serverand networkcomponents are provided on an “as is” and “as
        available”basis, withoutany warranties of any kind to the fullest extent permittedby applicablelaw. We expressly
        disclaim any and all conditions,representations,warranties or other terms, whether express or implied,including,
        butnot limited to, any implied warranties of merchantability,title, fitnessfor a particular purpose, and
        noninfringement. <span>You acknowledge that we do not warrant that the service(s)orwebsites will be uninterrupted, timely, secure orerror-free and youfurther acknowledge that we do not warrant that theaccess to theservice(s), which is provided over internet and varioustelecommunications networks, all of which are beyondour control, will be uninterrupted, timely, secure, error-free or free from virusesor other malicious software.the content on our websites is provided for generalinformation only. It is not intended to amount to advice on which you shouldrely. You mustobtain professional or specialist advice before taking,or refrainingfrom, any action on the basis of the content on ourwebsites. Noinformation or advice obtained by you from us or throughthe service(s)or websites shall create any warranty not expresslystated in theseterms.</span>
      </p>
      <h5>14. LIMITATION OF LIABILITY</h5>
      <p>To the fullest extent permitted by applicable law,in no event will we,our affiliates, officers, directors,
        employees, agents,suppliers orlicensors be liable to any person for any indirect,incidental, special,punitive,
        cover or consequential damages (including,without limitation,damages for lost profits, lost revenue, lost
        sales,lost goodwill, lossof use or lost content, impact on business, businessinterruption, lossof anticipated
        savings, loss of business opportunity)however caused,under any theory of liability, including,
        withoutlimitation, contract,tort, warranty, breach of statutory duty,negligenceor otherwise, evenif we have been
        advised as to the possibility of suchdamages or couldhave foreseen such damages. To the maximum extentpermitted
        byapplicable law, our aggregate liability and that ofour affiliates,officers, directors, employees, agents,
        suppliersand licensors,relating to the service(s), will be limited to anamount equal to thelower of (a) twelve
        months of the subscription chargesfor theservice(s) to which the claim relates; or (b) thesubscription
        chargespaid by you, for the service(s) to which the claimrelates prior to thefirst event or occurrence giving
        rise to such liability. You acknowledgeand agree that to provide you with the rights to accessand use
        theservice(s) in accordance with section 1, we have limitedour potentialliability and allocated risks based on
        the subscriptioncharges, whichwould have been substantially higher if we were toassume any furtherliability
        other than as set forth herein. <span>In jurisdictions which do not permit the exclusionof implied warrantiesor limitation of liability for incidental or consequentialdamages, ourliability will be limited to the greatest extent permittedby law.the limitations and exclusions also apply if thisremedy does not fullycompensate you for any losses or fails of its essentialpurpose.</span>
      </p>
      <h5>15. INDEMNIFICATION</h5>
      <h6>15.1 Circumstances</h6>
      <p>If the use of the Service(s) by You has become, or in Our opinion is likely to become, the subjectof any IP
        Claim (defined below), We may at Our own option and expense (a) procure for You theright to continue using the
        Service(s) as set forthhereunder; (b) replace or modify the Service(s)to make it non-infringing; or (c) if
        options (a) or(b) are not commercially and reasonablypracticable as determined by Us, terminate Your
        subscriptionto the Service(s) and repay You,on a pro-rated basis, any Subscription Charges Youhave previously
        paid Us for thecorresponding unused portion.</p>
      <h6>15.2 Indemnification by Us:</h6>
      <p>Subject to Your compliance with these Terms, We willindemnify and hold You harmless, fromand against any claim
        brought against You by a thirdparty alleging that the Service(s) Yousubscribed to infringes or misappropriates
        such thirdparty’s valid patent, copyright, ortrademark (an “IP Claim”). We shall, at Our expense,defend such IP
        Claim and pay damagesfinally awarded against You in connection therewith,including the reasonable fees
        andexpenses of the attorneys, provided that (a) You promptlynotify Us of the threat or notice ofsuch IP Claim;
        (b) We have or will have the sole andexclusive control and authority to selectdefense attorneys, defend and/or
        settle any such IPClaim; and (c) You fully cooperate with Usin connection therewith. We will have no liabilityor
        obligation with respect to any IP Claim ifsuch claim is caused in whole or in part by (i) compliancewith
        designs, data, instructions orspecifications provided by You; (ii) modificationof the Service(s) by anyone other
        than Us; or (iii)the combination, operation or use of the Service(s)with other hardware or software where
        theService(s) would not by themselves be infringing.</p>
      <h6>15.3 Our liability </h6>
      <p>Sections15.1 and 15.2 state Our sole, exclusive andentire liability to You and constitute Yoursole remedy with
        respect to an IP Claim brought byreason of access to or use of the Service(s)by You.</p>
      <h6>15.4 Indemnification by You:</h6>
      <p>You will indemnify and hold Suitable harmless againstany claim brought by a third party againstUs, and their
        respective employees, officers, directorsand agents arising from or related to useof the Service(s) or Websites
        by You in breach ofthese Terms or matters which You haveexpressly agreed to be responsible pursuant to
        theseTerms.</p>
      <h5>16. ASSIGNMENT; ENTIRE AGREEMENT; REVISIONS</h5>
      <h6>16.1 Terms of succession</h6>
      <p>You shall not, directly or indirectly, assign allor any of Your rights under these Terms or delegateperformance
        of Your duties under these Terms withoutOur prior written consent. We may,without Your consent, assign Our
        agreement with Youunder these Terms to in connection withany merger or change of Our control or the sale ofall
        or substantially all of Our assets providedthat any such successor agrees to fulfill its obligationspursuant to
        these Terms. Subject to theforegoing restrictions, these Terms will be fullybinding upon, inure to the benefit
        of and beenforceable by the parties and their respective successorsand assigns.</p>
      <h6>16.2 Entirety of the agreement</h6>
      <p>These Terms, together with any Form(s) and SupplementalTerms, constitute the entireagreement and supersede any
        and all prior agreementsbetween You and Us with regard to thesubject matter hereof. These Terms together with
        anyForm(s) and Supplemental Terms shallprevail over the terms or conditions in any purchaseorder or other order
        documentation You or any Entity You represent provides (all such terms or conditions being null and void), and,
        exceptas expressly stated herein, there are no other agreements,representations, warranties, orcommitments which
        may be relied upon by either partywith respect to the subject matter hereof.In the event of a conflict between
        any Form and theseTerms, these Terms shall prevail.</p>
      <h6>16.3 Revisions</h6>
      <p>We may amend these Terms and/or the Supplemental Termsfrom time to time, in which casethe new terms will
        supersede prior versions. Pleaseread these Terms of Service along with OurPrivacy Policy carefully before You
        start to use OurService(s) or Websites, as these will apply toYour use of the Service(s) and Our Websites.
        Pleasecheck these Terms from time to time totake notice of any changes We made, as they will bebinding on You.
        Your continued use of theService(s) following the effective date of any suchamendment may be relied upon by Us
        asYour acceptance of any such amendment. Our failureto enforce at any time any provision ofthese Terms does not
        constitute a waiver of that provisionor of any other provision of the Terms.</p>
      <h5>17. SEVERABILITY; NO WAIVER</h5>
      <p>If any provision in these Terms is held by a courtof competent jurisdiction to be unenforceable,such provision
        shall be modified by the court andinterpreted so as to best accomplish theoriginal provision to the fullest
        extent permittedby applicable law, and the remaining provisionsof these Terms shall remain in effect. Our
        non-exerciseof any right under or provision of theseTerms does not constitute a waiver of that right orprovision
        of the Terms.</p>
      <h5>18. EXPORT COMPLIANCE AND USE RESTRICTIONS; FEDERALGOVERNMENT END USEPROVISIONS</h5>
      <p>The Service(s) and other Software or components ofthe Service(s) which We may provide ormake available to You
        or Users may be subject to Indianlaws. You agree to comply with all suchlaws and regulations as they relate to
        access to anduse of the Service(s), Software and suchother components by You and Users. You shall not accessor
        use the Service(s) if You arelocated in any jurisdiction in which the provisionof the Service(s), Software or
        other componentsis prohibited under Indian or other applicable lawsor regulations (a “Prohibited Jurisdiction”)
        andYou shall not provide access to the Service(s) toany government, entity or individual located inany
        Prohibited Jurisdiction. You represent, warrantand covenant that (i) You are not named onany Indian government
        (or other government) list ofpersons or entities prohibited from receivingIndian exports, or transacting with
        any Indian person,(ii) You are not a national of, or a companyregistered in, any Prohibited Jurisdiction,
        (iii)You shall not permit Users to access or use theService(s) in violation of any Indian or other
        applicableexport embargoes, prohibitions orrestrictions, and (iv) You shall comply with all applicablelaws
        regarding the transmission oftechnical data exported from India and the countryin which You and Your Users are
        located.</p>
      <h5>19. RELATIONSHIP OF THE PARTIES</h5>
      <p>The parties are independent contractors. These Termsdo not create a partnership, franchise,joint venture,
        agency, fiduciary or employment relationshipamong the parties.</p>
      <h5>20. SURVIVAL</h5>
      <p>Sections 2 (Your Responsibilities), 5 (IntellectualProperty Rights), 7 (Billing, Plan Modificationand
        Payments), 8 (Suspension and Termination), 9 (Confidentiality),10 (Data Privacy andSecurity), 13 (Disclaimer of
        Warranties), 14 (Limitationof Liability), 15 (Indemnification), 20(Survival), 21 (Notices; Consent to electronic
        communication)and 23 (Governing Law andDispute Resolution) shall survive any terminationof Our agreement with
        respect to use of the Service(s) by You. Termination of such agreement shall not limit Your or Our liability
        forobligations accrued as of or prior to such terminationor for any breach of these Terms. </p>
      <h5>21. NOTICES; CONSENT TO ELECTRONIC COMMUNICATIONS</h5>
      <h6>21.1 Communication of Notices</h6>
      <p>All notices to be provided by Us to You under theseTerms may be delivered in writing (i) bynationally
        recognized overnight delivery service (“Courier”)or mail to the contact mailingaddress provided by You on any
        Form(s) or relateddocument while subscribing to theService(s); or (ii) electronic mail to the e-mailaddress
        provided for Your Account.</p>
      <h6>21.2 Issuance of notice</h6>
      <p>Our address for a notice to Us in writing by Courier or Mail is: Suitable Technology Pvt Ltd, 61, 3rd Floor,
        Kailash Hills, New Delhi -110065, with a CC to support@suitable.ai or electronic mail. All notices shallbe
        deemed to have been given immediately upon delivery by electronic mail, or if otherwise delivered upon receipt
        or, if earlier, two (2)business days after being deposited in the mail or with a Courier as permitted above.</p>
      <h5>22. ANTI-CORRUPTION</h5>
      <h6>22.1 Anti- corruption</h6>
      <p>You agree that You have not received or been offeredany illegal or improper bribe, kickback,payment, gift, or
        thing of value from any of Our employeesor agents in connection with theseTerms. Reasonable gifts and
        entertainment providedin the ordinary course of business do notviolate the above restriction. If You learn of
        anyviolation of the above restriction, You will use reasonable efforts to promptly notify Us at
        support@suitable.ai.</p>
      <h5>23. GOVERNING LAW AND DISPUTE RESOLUTION</h5>
      <h6>23.1 Governing laws</h6>
      <p>These Terms shall be governed by the laws of the Republicof India without regard to conflict oflaws principles.
        You hereby expressly agree to submitto the exclusive personal jurisdictioncourts of India, for the purpose of
        resolving anydispute relating to the Terms or Your access toor use of the Service(s).</p>
      <h6>23.2 Dispute resolution</h6>
      <p>Any dispute, claim or controversy arising out of orrelating to these Terms or the breach,termination,
        enforcement, interpretation or validitythereof, including the determination of thescope or applicability of
        these Terms to arbitrate,shall be settled by an arbitral panel composedof a single arbitrator appointed jointly
        by You andSuitable. In the event that You and Suitableare unable to agree on the sole arbitrator, 1
        (one)arbitrator shall be appointed by You and 1(one) arbitrator shall be appointed by Suitable, andthe 2 (two)
        appointed arbitrators shallnominate and appoint a third arbitrator who shallbe the chairperson of the
        arbitration panel(“Chairperson”). The seat of arbitration shall beHyderabad, India. The arbitration
        proceedingsshall be governed by the Arbitration and ConciliationAct, 1996 and shall be conducted in theEnglish
        language. The Chairperson shall also decideon the costs of the arbitration proceedings.The Chairperson’s award
        shall be substantiated inwriting and the Parties shall submit to theChairperson’s / arbitral panel’s award which
        shallbe enforceable in any competent court of law.</p>
      <h5>24. DEFINITIONS</h5>
      <p>When used in these Terms with the initial letterscapitalized, in addition to terms definedelsewhere in these
        Terms, the following terms havethe following meanings:<br/>
          <span>Account: means any accounts or instances created byor on behalf of You for access and use ofany of the Service(s).</span><br/>
          <span>Affiliate: means, with respect to a party, any entitythat directly or indirectly controls, is controlledby, or is under common control with such party, whereby“control” (including, with correlativemeaning, the terms “controlled by” and “under commoncontrol”) means the possession, directlyor indirectly, of the power to direct, or cause thedirection of the management and policies ofsuch person, whether through the ownership of votingsecurities, by contract, or otherwise.</span><br/>
          <span>Agent: means an individual authorized to use the Service(s)through Your Account for suchService(s) as an agent and/or administrator as identifiedthrough a User Login.</span><br/>
          <span>API: means the application programming interfacesdeveloped, enabled by or licensed to Usthat permits a User to access certain functionalityprovided by the Service(s).</span><br/>
          <span>API Policies: means the policies published on theWebsites (as specified below) that govern theuse of APIs, as updated from time to time.</span><br/>
          <span>Applicable Data Protection Law: means all data protectionand privacy laws and regulations ofthe Republic of India, relating to privacy and protectionof data or information, includingPersonal Data or Processing of Personal Data, or theEuropean Union General Data ProtectionRegulation (Regulation 2016/679) (GDPR), as may beapplicable;</span><br/>
          <span>Candidates: means any person or entity other thanthe Customer, Users or End-Users withwhom the Customer, Users or End-Users interact usingTalent Acquisition Platform or TalentDiscovery Platform and who have submitted their resumesor job applications to the Customer,User or End-User or appear for interviews for jobsposted bythe Customer, User or End-Userthrough their Account with Suitable;</span><br/>
          <span>Confidential Information: means all information disclosedby You to Us or by Us to You which isin tangible form and labeled “confidential” (or witha similar legend) or which a reasonableperson would understand to be confidential given thenature of the information andcircumstances of disclosure. For purposes of theseTerms, Service Data shall be deemedConfidential Information. Notwithstanding the foregoing,Confidential Information shall notinclude any information which (a) was publicly knownand made generally available in the publicdomain prior to the time of disclosure by the disclosingparty; (b) becomes publicly known andmade generally available after disclosure by the disclosingparty to the receiving party throughno action or inaction of the receiving party; (c)is already in the possession of the receiving partyat the time of disclosure by the disclosing partyas shown by the receiving party’s files andrecords prior to the time of disclosure; (d) is obtainedby the receiving party from a third partywithout a breach of such third party’s obligationsof confidentiality; (e) is independentlydeveloped by the receiving party without use of orreference to the disclosing party’sConfidential Information, as shown by documents andother competent evidence in the receiving party’s possession; or (f) is required by law to be disclosed by the receiving party,provided that the receiving party shall, to the extentlegally permitted, give the disclosing partywritten notice of such requirement prior to disclosingso that the disclosing party may seek aprotective order or other appropriate relief.</span><br/>
          <span>Customer: means any person or entity who has accessto, browses or uses the Service(s) orWebsite or intends to have access to, browse or usethe Service(s) or Website, either himself /herself / itself or through his / her / its employeesbeing the Users or End-Users.</span><br/>
          <span>Data Processing Addendum: means the data processingaddendum available athttps://Suitable/data-processing-addendum/ and asupdated from time to time, which shallgovern Service Data to the extent that it includesPersonal Data and involves transferring suchPersonal Data outside the European Economic Area orSwitzerland to any country not deemedby the European Commission as providing an adequatelevel of protection for personal data.</span><br/>
          <span>Documentation: means any written or electronic documentation,images, video, text or soundsspecifying the functionalities of the Service(s) providedor made available by Us to You or YourUsers through the Service(s) or otherwise.</span><br/>
          <span>End-User: means any person or entity other than Youor Your Users with whom You interactusing the Service(s).</span><br/>
          <span>Form: means any service order form referencing theseTerms and executed or approved by Youand Us with respect to Your subscription to the Service(s),which form may detail, among otherthings, the number of Users authorized to use theService(s) under Your subscription to theService(s) and the Subscription Plan(s) applicableto Your subscription to the Service(s).</span><br/>
          <span>Talent Acquisition Platform: means the end to endTalent Acquisition Platform. Its Website ishttps://portal.Suitable/. For the avoidance of doubt,If you use Suitable, you mean to use thisservice.</span><br/>
          <span>Talent Discovery Platform: means use of Suitable TalentDiscovery Platform integrated with yourcurrent ATS or HRMS. Its Website is https://portal.Suitable/.</span><br/>
          <span>Video Interview Platform: means use of Suitable VideoInterview Platform independently or inintegration with your current ATS or HRMS. Its Websiteis https://portal.Suitable/.</span><br/>
          <span>Talent Intelligence API: means the use of SuitableTalent Intelligence API, which used remotely.Its Website is https://api.Suitable.</span><br/>
          <span>Companies: means Suitable Technologies Pvt Ltd togetherwith its Affiliates.</span><br/>
          <span>Personal Data: means any information that relatesto a natural person, which, either directly orindirectly, in combination with other informationavailable or likely to be available with Suitable,is capable of identifying such person (or as defined under Applicable Data Protection Law) andincludes Sensitive Personal Data.</span><br/>
          <span>Privacy Policy: means Our privacy policy at https://Suitable/privacy-policy/as updated from timeto time.</span><br/>
          <span>Processing/To Process: means any operation or setof operations which is performed uponPersonal Data, whether or not by automatic means,such as collection, recording, organization,storage, adaptation or alteration, retrieval, consultation,use, disclosure by transmission,dissemination or otherwise making available, alignmentor combination, blocking, erasure ordestruction (or as defined under the Applicable DataProtection Laws).</span><br/>
          <span>Sensitive Personal Data: means data or informationof a person means such personalinformation which consists of information relatingto (i) password; (ii) financial information suchas bank account or credit card or debit card or otherpayment instrument details ; (iii) sexualorientation (iv) biometric information; (v) any detailrelating to the above clauses as may bemade available for providing the Service(s)); and(vi) any of the information made availableunder above clauses for storing or processing dataor information, stored or processed underlawful contract or otherwise (or as defined underthe Applicable Data Protection Laws).</span><br/>
          <span>Service Data: means all electronic data, text, messagesor other materials, including PersonalData of Users, End-Users and Candidates submittedto the Service(s) by You through YourAccount in connection with Your use of the Service(s),including, without limitation, PersonalData.</span><br/>
          <span>Service(s):mean and include Talent Acquisition Platform,Talent Discovery Platform, VideoInterview Platform and Talent Intelligence APIs orany new services that We may introduce as aService to which You may subscribe to and any updates,modifications or improvements to theService(s), including individually and collectively,Software, the API and any Documentation.You may subscribe to one or more of the Service(s).They may be subject to separate anddistinct Subscription Plans.</span><br/>
          <span>Software: means software provided by Us (either bydownload or access through the internet)that allows You to use any functionality in connectionwith the Service(s) and includes a MobileApplication.</span><br/>
          <span>Subscription Plan(s): means the pricing plan(s) andthe functionality and services associatedtherewith (as detailed on the Websites) for whichYou subscribe with respect to any User orEnd-User.</span><br/>
          <span>Subscription Term: means the period during which Youhave agreed to subscribe to theService(s) with respect to any individual User.</span><br/>
          <span>Supplemental Terms: means the Service(s) – specificterms set forth in section 25 below,additionally applicable to You when You enable, accessor use such Service(s).</span><br/>
          <span>Us: means Suitable Technologies Pvt Ltd., an Indian corporation, or any of its successors orassignees. In these Terms, Us may also be referredto as “We”, and “Our”.</span><br/>
          <span>User: means those who are designated users withinthe Service(s), including an Accountadministrator, Agents and other designated users.</span><br/>
          <span>Websites: means the websites for various Service(s)and other websites that Suitable operates.</span>
      </p>
      <h5>25. SUPPLEMENTAL TERMS</h5>
      <p>The Supplemental Terms below may contain terms thatare specific to one or more Service(s).For the avoidance of
        doubt, in the event of a conflictor inconsistency between the rest of theTerms and these Supplemental Terms,
        these SupplementalTerms shall prevail. The capitalizedterms in the Supplemental Terms shall have the samemeaning
        as ascribed to them in the Terms</p>
      <h6>1.A) For use of, third party integrations; modificationof existingfeatures &amp; new feature release</h6>
      <p>You acknowledge that (i) when You install or enableany integrations that are offered by Us or(ii) where (a) a
        feature is modified or (b) a newfeature is released within the Service(s), Youmay be shown additional terms
        governing their usage.Your continued usage of suchintegrations or such features may be relied upon byUs as Your
        acceptance of such additionalterms.</p>
      <h6>1. B) For use of sms, email and video interview communicationin</h6>
      <p>Suitablealso provides communication service as a part of theService(s) through the SMS, EMAIL andvideo
        interview mediums (“Communication Services”).If You use the Communication Service aspart of Suitable:</p>
      <p><span><b>SMS</b></span><br/>
        You understand and agree that (a) during Your useof the SMS communication feature, Suitableonly facilitates and
        enables communication to be sentvia SMS/text messages to the endreceiver and does not perform or provide any
        otherservices that are usually provided by a SMSservice provider or network provider; (b) if the ‘DoNot Disturb’
        (DND) or ‘Direct NumericalControl’ (DNC) is enabled, the Suitable carrier maynot be able to deliver the
        SMS/textmessage; (c) the delivery of the SMS/text messageis dependent on the network coverage ofthe receiver.
        The SMS/text message may not be deliveredor may be delayed if the networkcoverage of the receiver is weak or if
        the receiveris out of the network coverage area; ; (d) Youare solely responsible for Your use and operationof
        the SMS communication feature incompliance with all applicable laws in all jurisdictionsgoverning the use of the
        Service(s) byYou, Your Affiliates, Users and End-Users, includingbut not limited to recording and
        wiretappinglaws; and (e) If You use the SMS communication feature,a request for deletion of a contact
        orinformation such as the name of the contact, recordingsof that contact and any notes pertainingto such
        recordings shall also be deleted. However,information such as SMS logs may beretained for audit, fraud and
        reporting purposes inaccordance with applicable laws.</p>
      <p><span><b>EMAIL</b></span><br/>
        You understand and agree that (a) during Your use of the EMAIL communication feature,Suitable only facilitates
        and enables communication to be sent via EMAIL to the end receiverand does not perform or provide any other
        servicesthat are usually provided by a EMAILservice provider; (b) depending on the EMAIL settingsof the
        receiver, the EMAIL may getdelivered to the spam folder or junk folder of thereceiver; (c) the delivery of the
        EMAIL isdependent on the quality of and strength of the internetconnection used by the receiver. TheEMAIL may
        not be delivered or may be delayed if theinternet connection of the receiver isweak; (d) You are solely
        responsible for Your useand operation of the EMAIL communicationfeature in compliance with all applicable laws
        inall jurisdictions governing the use of theService(s) by You, Your Affiliates, Users and End-Users,including
        but not limited to recordingand wiretapping laws; and (e) ) If You use the EMAILcommunication feature, a request
        fordeletion of a contact or information such as the nameof the contact, recordings of that contactand any notes
        pertaining to such recordings shallalso be deleted. However, information such asEMAIL logs may be retained for
        audit, fraud and reportingpurposes in accordance withapplicable laws.</p>
      <p><span><b>VIDEO INTERVIEW</b></span><br/>
        You understand and agree that (a) unless You chooseto turn on the recording feature, all videointerviews using
        the video service are not recorded;(b) the video interview is not intended tosupport general video conferencing
        and is dependenton internet bandwidth available, (c) Wewill not be held liable for any claim, damages orloss
        (and You hereby waive any and all suchclaims or causes of action), arising from or relatingto Your (or Users or
        End-Users) inability touse the video interview service to make other usages,(d) You are solely responsible for
        Youroperation of the video interview service in compliancewith all applicable laws in all jurisdictionsgoverning
        the use of the Service(s) by You, Your Affiliates,Users and End-Users, including butnot limited to recording and
        wiretapping laws, and(e) If You use video interviewing service, arequest for deletion of a contact or
        information suchas the name of the contact, recordings ofthat contact and any notes pertaining to such
        recordingsshall also be deleted. However,information such as logs containing interviews mademay be retained for
        audit, fraud andreporting purposes in accordance with applicable laws.You undertake and agree that You will
        defend, holdharmless and indemnify Us from andagainst any third party claim relating to or arisingfrom any of
        the foregoing. We may disable theService provided to You if (i) Your subscription tothe Service(s), Account or
        rights to accessand/or use the Service(s) are otherwise suspended,or terminated; and/or (ii) You violate any
        ofthese Terms.</p>
      <h6>1.C) For use of talent acquisition platform and talentdiscoveryplatformusing talent acquisition platform and
        talent discoveryplatform</h6>
      <p>You acknowledge and agree that You will access anduse Talent Acquisition Platform only forYour internal
        business purposes and that You willnot provide any third party with access toTalent Acquisition Platform through
        Your Account.You further agree that We will not be liable forany loss or corruption of Service Data, or for
        anycosts or expenses associated with backing-upor restoring any of Service Data or any claims, losses,expenses,
        costs or damages arising fromsuch unauthorized use of Talent Acquisition Platform.</p>
      <h6>2. Talent acquisition platform and talent discovery data2.</h6>
      <h6>1 Definition</h6>
      <p>Talent Acquisition Platform Data” / “Talent DiscoveryPlatform Data” means any data that iscollected from Your
        Users, End-Users and Candidatesthrough Your Account in connection withYour use of Talent Acquisition Platform or
        TalentDiscovery Platform, as the case may be,including but not limited to the following:<br/>
    <span>a) Directory. Contact information, such as name, emailaddress, mailing address, phonenumber, date of birth, gender, emergency contact informationof the Users, End-Users orCandidates collected at the time of employment andcreation of employee profile. Subject to theSubscription Plan to which You have subscribed, Youmay have the option to collect additionalinformation, including Sensitive Personal Data, fromUsers, End-Users or Candidates. The Service allows the Users, End-users and Candidatesto modify, rectify and update their PersonalData within the Service<br/>
    <span>b) Application Form. Candidates’ contact information,such as name, email address, mailingaddress, phone number, links to Candidates’ socialnetworking profiles or any custom field thatYou may add to the form to be filled by Candidatesat the time of submitting their resumes.</span></span>
      </p>
      <h6>2.2 Deletion of data</h6>
      <p>If You are a Customer of Our Service, Talent AcquisitionPlatform, You may at any time deletedata associated
        with a Candidate from within TalentAcquisition Platform. As a customer ofTalent Acquisition Platform, You may
        delete a User’sor End-User’s data from Talent AcquisitionPlatform. Upon Your deletion of User’s data,
        TalentAcquisition Platform will retain informationlike business e-mail address, identification
        number,designation of such User or End-User(“Retained User Information”) in order to not breakany old
        associations relating to such User orEnd-User within Your Account. Except Retained UserInformation, all the
        other data that youdelete will be deleted from Talent Acquisition Platform.Retained User Information will be
        deletedin accordance with the Terms. If You wish to permanentlydelete a User’s or End-User’sRetained User
        Information, You can write to support@suitable.ai.</p>
      <h6>3. Data retention</h6>
      <p>We strongly recommend that You export all ServiceData before You terminate Your Account. In any event,
        following the termination of Your Accounteither by You or Us:</p>
      <h6>3.1 Retention for active users</h6>
      <p>If You have subscribed to any Subscription Plan, ServiceData will be retained for a period of 15days (“Data
        Retention Period”) from such terminationwithin which You may contact Us to exportService Data. Beyond this Data
        Retention Period, Wereserve the right to delete all Service Datain the normal course of operation. Service Data
        cannotbe recovered once it is deleted.Notwithstanding anything contained in the Terms orSupplemental Terms, We
        may, in Our solediscretion, continue to store data / information suchas email and contact information, billing
        andinvoice information, payment, late payment or non-paymentinformation or such relatedinformation of the
        Customer for record keeping, audit,fraud and reporting purposes inaccordance with applicable laws.</p>
      <h6>3.2 Retention for inactive users</h6>
      <p>If You have signed-up to Talent Acquisition Platformbut not activated Your Account within 7days from such
        sign-up, We reserve the right to deleteYour Account immediately upon theexpiry of the aforementioned 7 days.</p>
      <h6>4.ADDITIONAL TERMS FOR CERTAIN FEATURES OF Suitable</h6>
      <h6>4.1 Third Party Platforms.</h6>
      <p>If a Candidate shares Personal Data with you throughYour Account, You shall be solelyresponsible for such
        Personal Data whether it is publiclyposted or privately transmitted throughYour Account, on third-party
        platforms such as Careerbuilder,Dice, Naukri, Monster, Indeed,Facebook, Twitter, Google+, LinkedIn and similar
        others(“Third Party Platforms”). These ThirdParty Platforms are governed by their own terms andprivacy policies
        and You agree that Wehave no control over these Third Party Platforms andthat We are not responsible for
        youraccess or use of these Third Party Platforms, or forany loss or corruption of Service Data, or forany costs
        or expenses associated with backing up orrestoring any of Service Data, or anyclaims, losses, expenses, costs or
        damages arisingfrom such use of the Third Party Platforms.</p>
      <h6>4.2 Rating</h6>
      <p>Talent Acquisition Platform has a Rating feature throughwhich You will have the ability to ratethe Candidates.
        You acknowledge and agree that Wedo not have any control on the ratings thatYou provide to the Candidates.
        Further, You agreeto use this feature in compliance with allapplicable laws and if Your use of this feature
        violatesany applicable law, You shall immediatelycease using this feature and We shall not be liablefor any such
        violation by You in any mannerwhatsoever.</p>
      <h6>4.3 Subjectivity of Analysis</h6>
      <p>If any Candidate shares Personal Data with You throughTalent Acquisition Platform, Youacknowledge that for the
        purposes of providing Youwith the Resume Parsing feature withinTalent Acquisition Platform (if available as
        partof Your Subscription Plan), The results derivedby our tool shall be displayed for Your review underPool
        within Talent Acquisition Platform. Youunderstand that the analysis presented is based onthe accuracy of the
        data shared and Weshall not be liable to You for any inability to useor interpret the analysis.</p>
      <h6>4.4 Personal data disclosure</h6>
      <p>We use third party services within Talent AcquisitionPlatform to allow you to post about jobvacancies. In the
        event You choose to include PersonalData in such posts, You agree that Wehave no control over how such third
        parties handleYour Personal Data and that We are notresponsible for any loss or corruption of such
        PersonalData.</p>
      <h6>4.5 Candidates’ Rights:</h6>
      <p>Notwithstanding anything contained in the Terms orSupplemental Terms, a Candidate shall beentitled to request
        the deletion of any of the Candidate’sPersonal Data or Sensitive PersonalData, as the case may be, at any time.
        Further, aCandidate shall continue to be entitled torequest the deletion of the Candidate’s Personal Dataor
        Sensitive Personal Data, as the casemay be, irrespective of successfully completing theinterviewing process or
        being hired by theCustomer.</p>
      <p></p></div>
  </div>
}
export default TermsCondition;