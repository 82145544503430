import React from 'react';
import styles from './styles.module.css';
import dummyPic from "../../Pages/BlogsArchive/assets/dummy-Pic.png";
import parse from 'react-html-parser';


const BlogCard = props => {
  const {img, title, content, onClick} = props;
  return (<div className={styles.blogCard} onClick={onClick}>
    <div className={styles.blogCardImgContainer}>
      <img src={img || dummyPic} className={styles.blogCardImg} loading={"lazy"} alt={'banner-image'} width={'100%'}/>
    </div>
    <div className={styles.blogCardDetailsContainer}>
      <div className={styles.blogCardDetailsHeader}>
        {title? parse(title) : 'No Title'}
      </div>
      <div className={styles.blogCardText}>
        {content? parse(content) : 'No Content'}
      </div>
    </div>
  </div>)
}

export default BlogCard;