import React, {useEffect, useLayoutEffect, useMemo} from "react";
import { Link, useLocation } from 'react-router-dom';
import "./style.css";
import commonStyles from '../../Common/Styles/commonStyles.module.css';
import logo from "./assets/white logo.png";
import classNames from "classnames";

export default () => {
  const location = useLocation();
  const isMobileDevice = useMemo(() => window.matchMedia('(max-width: 767px)').matches, []);
  // useLayoutEffect (() => {
  //   window.scrollTo(0,0);
  //   const navContainer = document.querySelector('#nav-container');
  //   if (!isClientHomePage && !isMobileDevice)
  //   window.onscroll = scrollfunction;
  //   else return
  //   function scrollfunction () {
  //     if (document.documentElement.scrollTop > 249) {
  //       navContainer.classList.remove('anime-nav');
  //     } else
  //       navContainer.classList.add('anime-nav')
  //   }
  //
  //   return () => {
  //     if (window.onscroll)
  //       window.onscroll = null;
  //   }
  // },[location])
  return (
    <nav className="navbar fixed-top navbar-expand-md navbar-dark">
      <div className={classNames("container-fluid", commonStyles.pageContainerWidth)} id='nav-container'>
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo-img" width={120} height={'auto'}/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/about-us">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                  className="nav-link"
                  to={'/blogs'}
              >
                Blogs
              </Link>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <Link className="nav-link" to="#">*/}
            {/*    Apply as a Recruiter*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className="nav-item">
              <Link
                  className="nav-link"
                  to='/recruiters'
              >
                For Recruiters
              </Link>
            </li>
            <li className="nav-item">
              <a
                  className={classNames("nav-link", !isMobileDevice ? 'loginBtn' : '')}
                  href='https://app.suitable.ai/login'
                  target="_blank"
                  rel="noreferrer"
              >
                Login
              </a>
            </li>
          </ul>
          {/*<form className="container-fluid d-flex justify-content-md-end justify-content-center">*/}
          {/*  <a className="btn btn-outline-custom" type="button">*/}
          {/*    CONTACT*/}
          {/*  </a>*/}
          {/*</form>*/}
        </div>
      </div>
    </nav>
  );
};
