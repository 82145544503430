import * as React from "react";
const SmallUnderlineSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={178.424}
        height={14.046}
        viewBox="0 0 178.424 14.046"
        {...props}
    >
      <path
          id="Path_1294"
          data-name="Path 1294"
          d="M0,1S41.218-3.577,83.767-3.577h85.188"
          transform="translate(4.969 8.077)"
          fill="none"
          stroke="#dbc446"
          strokeLinecap="round"
          strokeWidth={7}
      />
    </svg>
);
export default SmallUnderlineSVG;
