import React from 'react';
import "./styles.css";
import 'aos/dist/aos.css';
import {Route, Routes} from 'react-router-dom';
import {BasicLayout} from "./Layouts/BasicLayout";
import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsCondition from "./Pages/TermsCondition";
import BlogsArchive from "./Pages/BlogsArchive";
import SingleBlog from "./Pages/SingleBlog";
import NotFound from "./Pages/NotFound";
import { inject } from '@vercel/analytics';
import ClientHomePage from "./Pages/ClientHomePage";

inject({ debug: false, mode: 'production' });
export default function App() {
  return (<div>
    <Routes>
      <Route path="/" element={<BasicLayout/>}>
        <Route index element={<ClientHomePage/>}/>
        <Route path="recruiters" element={<HomePage/>}/>
        <Route path="about-us" element={<About/>}/>
        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="terms-condition" element={<TermsCondition/>}/>
        <Route path="blogs" element={<BlogsArchive />}/>
        <Route path='blogs/:id' element={<SingleBlog />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </div>)
}
