import React, {useMemo, useEffect} from "react";
import NumberScroller from "react-number-scroller";
import styles from "../HomePage/styles.module.css";
import AOS from 'aos';
import bgFirstSection from '../HomePage/assets/bgfirstSectionComp.webp';
import bgPipelineSection from '../HomePage/assets/bgpipelineSectionComp.webp';
import bgCallToActionSection from '../HomePage/assets/bgCallToActionSectionComp.webp';
import StrikeLineSVG from "../HomePage/strikeLineSVG";
import SmallUnderlineSVG from "../HomePage/smallUnderlineSVG";
import AiUnderlineSVG from "../HomePage/AiUnderlineSVG";
import FindUnderlineSVG from "../HomePage/FindUnderlineSVG";
import clientLogo1 from "../HomePage/assets/xencov.jpeg";
import clientLogo2 from "../HomePage/assets/codalien.jpeg";
import clientLogo3 from "../HomePage/assets/dineout.jpeg";
import clientLogo4 from "../HomePage/assets/zuddl.jpeg";
import clientLogo5 from "../HomePage/assets/kodekloud.jpeg";
import clientLogo6 from "../HomePage/assets/mindpeers.jpeg";
import clientLogo7 from "../HomePage/assets/kalendar.jpeg";
import clientLogo8 from "../HomePage/assets/danip.jpeg";
import allinLogo from "../HomePage/assets/allinLogo.svg";
import fiveHundredLogo from "../HomePage/assets/fiveHundredLogo.svg";
import avatar1 from "../HomePage/assets/avatar.svg";
import avatar2 from "../HomePage/assets/avatar2.svg";
import avatar3 from "../HomePage/assets/avatar3.svg";
import avatar4 from "../HomePage/assets/avatar4.svg";
import aboutSectionIllustration from "./assets/aboutSectionIllustration.svg";
import aboutSectionIllustration2 from "./assets/aboutSectionIllustration2.svg";
import aboutSectionIllustration3 from "../HomePage/assets/aboutSectionIllustration3.svg";
import aboutSectionIllustration4 from "./assets/aboutSectionIllstration4.svg";
import aboutSectionIllustration5 from "./assets/aboutSectionIllustration5.svg";
import classNames from "classnames";
import commonStyles from '../../Common/Styles/commonStyles.module.css'
import {Helmet} from "react-helmet";

const ClientHomePage = () => {
  const isMobileDevice = useMemo(() => window.matchMedia('(max-width: 767px)').matches,[]);

  useEffect (() => {
    AOS.init({once: true});

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    let firstLinedSvg = document.querySelector('#firstLinedSvg');
    let secondLinedSvg = document.querySelector('#secondLinedSvg');
    let thirdLinedSvg = document.querySelector('#thirdLinedSvg');
    let fourthLinedSvg = document.querySelector('#fourthLinedSvg');
    let fifthLinedSvg = document.querySelector('#fifthLinedSvg');
    let sixthLinedSvg = document.querySelector('#sixthLinedSvg');
    let seventhLinedSvg = document.querySelector('#seventhLinedSvg');
    let eightLinedSvg = document.querySelector('#eightLinedSvg');

    observer.observe(firstLinedSvg);
    observer.observe(secondLinedSvg);
    observer.observe(thirdLinedSvg);
    observer.observe(fourthLinedSvg);
    observer.observe(fifthLinedSvg);
    observer.observe(sixthLinedSvg);
    observer.observe(seventhLinedSvg);
    observer.observe(eightLinedSvg);
  },[])
  const animationDuration = useMemo(() => window.matchMedia("(max-width: 767px)").matches ? 90 : 1000, [])
  return (
      <div>
        <Helmet>
          <title>Suitable AI - Global Talent Referral Marketplace</title>
          <link href={window.location.href} rel="canonical"/>
        </Helmet>
        <div className={styles.homeAgencyFirstSection} style={{backgroundImage: `url(${bgFirstSection})`}}>
          <div className="container-fluid">
            <div className="row">
              <div className={classNames(commonStyles.pageContainerWidth, "col position-relative")}>
                <div className={classNames(styles.homeAgencyFirstSectionContent, "pb-5")}>
                  <div data-aos-duration={animationDuration} data-aos='fade-up'
                       className={classNames(styles.homeAgencyFirstSectionHeading, "text-white text-center pt-5 mt-1")}>
                    <span className={styles.linedSectionContainer}>AI-Enhanced
                    <StrikeLineSVG id={'firstLinedSvg'} className={styles.firstLinedImg} />
                    </span> Talent Sourcing by <br/>
                    Top Recruiters
                  </div>
                  <div data-aos-duration={animationDuration} data-aos='fade-up'
                       className={classNames(styles.homeAgencyFirstSectionSummary, "fst-italic")}>
                    <span className={styles.homeAgencyFirstSectionSummaryHighlight}>
                       Combining AI precision with elite recruiter expertise
                    </span>
                    &nbsp;to redefine your talent acquisition strategy.
                  </div>
                  <div data-aos-duration={animationDuration} data-aos="fade-up"
                       data-aos-anchor-placement="top-bottom"
                       className={classNames(styles.homeAgencyTagsSectionContainer, " mt-5 mb-4")}>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Talent Access</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Precision Matching</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Speedy Hiring</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Quality Candidates</span>
                    <span className={classNames(styles.textBgCustom, styles.roundedPillCustom, "badge fw-light me-2")}>Smart Filtering</span>
                  </div>
                  <div className={styles.firstSectionBtnContainer}>
                    <a href={'https://calendly.com/nikhil-suitableai/30min'}
                       target="_blank"
                       rel="noreferrer"
                       className={classNames(styles.btnTheme, "btn")}
                    >
                      Start Hiring 🕵️‍♂️
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.homeAgencyClientsSection, "mb-5")} style={{backgroundImage: `url(${bgPipelineSection})`}}>
          <div data-aos-duration={animationDuration}
               className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyClientsSectionBox, "pt-3")}>
            <div className={classNames(styles.homeAgencyClientsSectionHeading, "pb-3")}>
              Our Pipeline Quality (Shortlist Rates)
            </div>
            <div
                className={classNames(styles.homeAgencyClientsSectionContent, "justify-content-between mt-3 d-flex pb-4")}>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo1}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Xencov</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={57} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo2}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Codalien</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={58} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo3}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Dineout</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={62} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo4}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Zuddl</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={58} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo5}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>KodeKloud</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={68} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo6}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Mindpeers</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={68} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo7}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Kalendar AI</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={92} renderFrequency={70}/>%</div>
              </div>
              <div className={styles.homeAgencyClientsCard}>
                <img loading="lazy"
                    src={clientLogo8}
                    className={styles.homeAgencyClientsLogo}
                    alt="clients logo"
                    height='auto' width={'48'}
                />
                <div className={styles.homeAgencyClientsName}>Danip</div>
                <div className={styles.homeAgencyClientsPercentage}><NumberScroller to={80} renderFrequency={70}/>%</div>
              </div>
            </div>
          </div>
        </div>
        <section data-aos-duration={animationDuration} data-aos={"fade-up"}
                 className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyBackedBySection)}>
          <div className={classNames(styles.homeAgencyBackedBySectionBox, 'gap-2 justify-content-center')}>
            <div className={classNames(styles.homeAgencyBackedByHeading, 'fw-bold fs-3')}>Backed By</div>
            <div className={classNames(styles.homeAgencyBackedByContentBox, 'd-flex align-items-center')}>
              <span>
                <a href={'https://500.co/'} target={'_blank'}>
                  <img loading="lazy" src={fiveHundredLogo} alt={'logo'}
                       className={styles.homeAgencyBackedUpSectionLogo} width={124} height={124}/>
                </a>
              </span>
              <span>
                <a href={'https://allincapital.vc/'} target={'_blank'}>
                  <img loading="lazy" src={allinLogo} alt={'logo'}
                       className={styles.homeAgencyBackedUpSectionLogo} width={124} height={124}/>
                </a>
              </span>
            </div>
            <div
                className={classNames(styles.homeAgencyBackedByContentBox, 'd-flex align-items-center')}>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar1} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Patrick Lee</div>
                  <div>Co-founder</div>
                  <div>Rotten Tomatoes</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar2} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Liron Shapira</div>
                  <div>Co-founder</div>
                  <div>Relationship Hero</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar3} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Abhishek Ayyagari</div>
                  <div>Co-founder</div>
                  <div>yBANQ, acquired by Clear</div>
                </div>
              </div>
              <div className={classNames(styles.homeAgencyBackedByCard, 'd-flex align-items-center justify-content-center')}>
                <img loading="lazy" src={avatar4} alt={'avatar'} className={styles.homeAgencyBackedByAvatar} height='auto' width={79}/>
                <div className={classNames(styles.homeAgencyBackedByCardContent, 'ms-1')}>
                  <div>Taher Savliwala</div>
                  <div>Co-founder</div>
                  <div>Relief Technologies Inc</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos-duration={animationDuration} data-aos={"fade-up"}
                 className={classNames(styles.homeAgencySecondHeadingSection, "text-center fs-1")}>
          Created by {isMobileDevice ? <br /> : ''}<span
            className={classNames(styles.linedSectionContainer, 'fw-bold')}>Talent Teams
          <StrikeLineSVG id={'secondLinedSvg'} className={classNames(styles.firstLinedImg, styles.foundersLinedImg)} />
        </span> <br/>
          who Empathise with your pain
        </section>
        <div
            className={classNames(commonStyles.pageContainerWidth, styles.homeAgentAboutSection)}>
          <div className={'home-agent-about-section-alternating-illustration-section'}>
            <div className={'home-agent-about-alternating-illustration-section'}>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator, "row")}>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      <span className={'position-relative'}>Network
                      <SmallUnderlineSVG id={'thirdLinedSvg'} className={styles.firstAboutSectionHeadUnderline} />
                    </span> of Specialized Recruiting Agencies
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Rapid talent access via a <strong>global network of 1500+ specialized</strong> Recruiting Agencies.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Localized recruitment expertise ensuring fast sourcing, <strong>starting within just 3 hours.</strong>
                    </div>
                    <div data-aos-duration={animationDuration} data-aos="fade-up"
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Diverse talent pool spanning <strong>multiple industries and region</strong> for optimal candidate fit.
                    </div>

                  </div>
                </div>
                <div data-aos-duration={animationDuration} data-aos="fade-up"
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration}
                      alt="illustration"
                  />
                </div>
              </div>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator2, "row")}>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration2}
                      alt="illustration"
                  />
                </div>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      <span className="position-relative">Data-Backed
                        <AiUnderlineSVG id={'fourthLinedSvg'} className={styles.aiScreeningLine} />
                      </span> Decisions
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Empower decisions with data: <strong>leverage rejection trends and job analytics </strong>
                      for strategic hiring.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Effective market mapping</strong> for understanding talent landscapes and competition.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Increase <strong>hiring precision with actionable insights</strong>, leading to better talent alignment.
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div className={'home-agent-about-alternating-illustration-section mt-5'}>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator, "row")}>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      Minimal <span className="position-relative">Admin Effort
                      <FindUnderlineSVG id={'fifthLinedSvg'} className={styles.findUnderline}/>
                    </span>
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Simplify recruitment logistics:</strong> zero vendor search, single agreement, and invoicing process.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong> Streamline feedback loops</strong> for efficient communication and quicker hiring decisions.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Reduce administrative burden, allowing <strong>focus on strategic aspects</strong> of talent acquisition.
                    </div>
                  </div>
                </div>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, styles.homeAgentSectionIllustrationContainer3, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration3}
                      alt="illustration"
                  />
                </div>
              </div>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator2, "row")}>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, 'col-12 col-md-6')}>
                  <img loading="lazy"
                      className={styles.homeAgentAboutSectionIllustrationImage}
                      height='auto' width={'90%'}
                      src={aboutSectionIllustration4}
                      alt="illustration"
                  />
                </div>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      Interview Ready <span className="position-relative">Vetted Candidates
                      {/*<SmallUnderlineSVG id={'sixthLinedSvg'} className={styles.laserFocusLine} />*/}
                      <StrikeLineSVG id={'sixthLinedSvg'} className={styles.firstLinedImg} />
                    </span>
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Multi-level candidate screening</strong> only the best reach the interview stage.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Enhance employer brand and candidate experience, <strong>reducing no-show rates</strong> significantly.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Consistent delivery of high-quality candidates</strong>, ready for immediate interviews.
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.homeAgentAboutSectionEncapsulator, "row")}>
                <div className="col">
                  <div className={styles.homeAgentAboutSectionBox}>
                    <div className={classNames('text-theme')}></div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionMainHeading, "fs-2 fw-bold")}>
                      <span className="position-relative">ATS Integrations
                      <FindUnderlineSVG id={'seventhLinedSvg'} className={styles.findUnderline}/>
                    </span>
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong>Seamless ATS integration</strong> for efficient candidate tracking and hiring pipeline management.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 <strong> Optimized workflow management</strong> within your ATS, ensuring a smooth recruitment process.
                    </div>
                    <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                         className={classNames(styles.homeAgentAboutSectionSubheading, "fs-5 mt-5 mb-2")}>
                      👉 Enhanced efficiency for interviewers with <strong> reduced time-to-hire and talent acquisition.</strong>
                    </div>
                  </div>
                </div>
                <div data-aos-duration={animationDuration} data-aos={"fade-up"}
                     className={classNames(styles.homeAgentAboutSectionIllustrationContainer, styles.homeAgentSectionIllustrationContainer3, 'col-12 col-md-6')}>
                  <img loading="lazy"
                       className={styles.homeAgentAboutSectionIllustrationImage}
                       height='auto' width={'90%'}
                       src={aboutSectionIllustration5}
                       alt="illustration"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className={classNames(styles.callToActionSectionContainer, 'mt-5')} style={{backgroundImage: `url(${bgCallToActionSection})`}}>
          <div className={classNames(commonStyles.pageContainerWidth, styles.homeAgencyCallToActionSectionBox)}>
            <div className="pb-3 fs-1">
              Join 100+   <span className="position-relative">Companies
              <SmallUnderlineSVG id={'eightLinedSvg'} className={styles.foundersLinedImg} />
            </span> Excelling with<br/>
              <span className="position-relative"> Suitable AI
                {/*<TalentUnderlineSVG id={'ninthLinedSvg'} className={styles.laserFocusLine} />*/}
              </span>
            </div>
            <div className={styles.homeAgencyCallToActionSectionContent}>
              Revolutionize your recruitment with Suitable AI. Discover efficiency, quality, and speed in talent acquisition. Transform your hiring strategy.
            </div>
            <div className="mt-4 pb-4">
              <a href={'https://calendly.com/nikhil-suitableai/30min'}
                 target="_blank"
                 rel="noreferrer"
                 className={classNames(styles.btnTheme, "btn mt-4")}
              >
                Book Your Demo Today 🗓️
              </a>
            </div>

          </div>
        </section>

      </div>
  );
};

export default ClientHomePage;