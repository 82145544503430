import React, {useEffect, useMemo, useState} from 'react';
import styles from './styles.module.css';
import {Helmet} from "react-helmet";
import commonStyles from '../../Common/Styles/commonStyles.module.css';
import classNames from "classnames";
import BlueSection from "../../Components/BlueSection";
import BlogCard from "../../Components/BlogCard";
import {postPath} from "../../config/config";
import Pagination from "../../Components/Pagination";
import {useNavigate} from 'react-router-dom';

export default function (props) {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const per_page = '9';
  const options = useMemo(() => ('/?_embed=true' + '&per_page=' + per_page + '&page='  + currentPage), [currentPage]);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const pageNumberLimit = 5;
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const onPageChange= (pageNumber)=>{
    setCurrentPage(pageNumber);
  }

  const onPrevClick = ()=>{
    if((currentPage-1) % pageNumberLimit === 0){
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage(prev=> prev-1);
  }

  const onNextClick = ()=>{
    if(currentPage+1 > maxPageLimit){
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage(prev=>prev+1);
  }

  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    totalPages: totalPages
  };

  useEffect (() => {
    setLoading(true);
    fetch(postPath + options).then(response => {
      if (totalPages === null)
        setTotalPages(response.headers.get('X-WP-TotalPages'));
      return response.json()
    })
        .then(data => {
          setPosts(data);
          setLoading(false);
        })
        .catch(err => console.log(err));
  },[currentPage])

  return <div className={classNames(styles.blogPageContainer, commonStyles.pageContainerWidth)}>
    <Helmet>
      <title>Suitable AI - Blog</title>
      <link href={window.location.href} rel="canonical"/>
    </Helmet>
    <section className={styles.pageHeader}>
      Blogs
    </section>
    <section className={styles.blogsArchivesContainer}>
      {posts.length !== null ? posts?.map(post => <BlogCard onClick={() => navigate('/blogs/' + post?.slug)} key={post?.id} title={post?.title?.rendered}
                                                     img={post?._embedded['wp:featuredmedia']['0']?.media_details?.sizes?.medium_large?.source_url} content={post?.excerpt?.rendered} />) : ''}
    </section>
    <div className={commonStyles.loaderContainer} id={'loading-container'}>
      {loading ? <div className={commonStyles.loader}/> : <div className={styles.paginationContainer}>
        <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange}
        />
      </div>}
    </div>

    <BlueSection />
  </div>
}