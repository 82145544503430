import React, {useEffect, useState} from 'react';
import styles from './styles.module.css';

const Pagination = (props) => {

  const { currentPage, maxPageLimit, minPageLimit, totalPages, onPrevClick, onNextClick, onPageChange } = props;

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pagesArr = [];
    for (let i=1; i <= totalPages; i++) {
      pagesArr.push(i);
    }
    setPages([...pagesArr])
  },[])

  const pageNumbers = pages.map(page => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
          <li key={page} id={page} onClick={e => onPageChange(Number(e.target.id))}
              className={currentPage === page ? styles.active : null}>
            {page}
          </li>
      );
    } else {
      return null;
    }
  })

  //page ellipses
  let pageIncrementEllipses = null;
  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <li className={styles.ellipses} onClick={() => onNextClick()}>&hellip;</li>
  }
  let pageDecrementEllipses = null;
  if (minPageLimit >= 1) {
    pageDecrementEllipses = <li className={styles.ellipses} onClick={() => onPrevClick()}>&hellip;</li>
  }

  return <div>
    <ul className={styles.paginationElement}>
      <li>
        <button onClick={onPrevClick} disabled={currentPage === pages[0]}>Prev</button>
      </li>
      {pageDecrementEllipses}
      {pageNumbers}
      {pageIncrementEllipses}
      <li>
        <button onClick={onNextClick} disabled={currentPage === pages[pages.length-1]}>Next</button>
      </li>
    </ul>
  </div>
}

export default Pagination;